import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { API } from "../proxy/BaseHttp";

import { RoutePath } from "../proxy/RootRouter";
import { NavBar } from "zarm";
import Switch from "react-switch";

export default class About extends Component {
  state = {
    isOk: false,
    isPath: false,
    _device: false,
    _remoteAuth: false,
    _name: "",
  };

  componentDidMount() {
    this.user = window.localStorage.getItem("user");
    if (this.user) this.user = JSON.parse(this.user);

    var device = window.localStorage.getItem("lock2");
    if (device) {
      device = JSON.parse(device);
      this.setState({ _device: device });
      this.lockGetSwitch(device);
    }
  }
  async lockGetSwitch(device) {
    try {
      var res = await API.lockGetSwitch(device["expeedDeviceId"]);
      if (res && res.result_code === 0) {
        this.setState({
          _device: {
            ...device,
            snapshotSwitch: res.data.snapshotSwitch,
            alertSwitch: res.data.alertSwitch,
            openSwitch: res.data.openSwitch,
          },
        });
      }
    } catch (e) {}
  }
  async lockUnbindAdmin() {
    try {
      const { _device } = this.state;
      var res = await API.lockUnbindAdmin(_device["expeedDeviceId"]);
      if (res && res.result_code === 0) {
        this.setState({ isOk: true, isPath: RoutePath.home });
      } else {
        window.alert("删除失败");
      }
    } catch (e) {}
  }
  async changeName() {
    try {
      const { _name, _device } = this.state;
      var res = await API.lockSetAlias(_device["expeedDeviceId"], _name);
      if (res && res.result_code === 0) {
        var item = _device;
        item["aliasName"] = _name;
        this.setState({
          _device: { ..._device, aliasName: _name },
          _remoteAuth: false,
        });
      } else window.alert(res.result_msg);
    } catch (e) {}
  }
  changeSwitch(key) {
    const { _device } = this.state;
    if (key === 1) {
      var b = _device["openSwitch"] ? true : false;
      this.lockUpdateSwitch({ openSwitch: b ? 0 : 1 });
      this.setState({ _device: { ..._device, openSwitch: b ? 0 : 1 } });
    } else if (key === 2) {
      var b = _device["alertSwitch"] ? true : false;
      this.lockUpdateSwitch({ alertSwitch: b ? 0 : 1 });
      this.setState({ _device: { ..._device, alertSwitch: b ? 0 : 1 } });
    } else if (key === 3) {
      var b = _device["snapshotSwitch"] ? true : false;
      this.lockUpdateSwitch({ snapshotSwitch: b ? 0 : 1 });
      this.setState({ _device: { ..._device, snapshotSwitch: b ? 0 : 1 } });
    }
  }
  async lockUpdateSwitch(body) {
    try {
      const { _device } = this.state;
      var res = await API.lockUpdateSwitch({
        ...body,
        deviceId: _device["expeedDeviceId"],
      });
      if (res && res.result_code === 0) {
      }
    } catch (e) {}
  }

  render() {
    const { isOk, isPath, _device } = this.state;
    if (isOk) return <Redirect to={{ pathname: isPath, state: {} }} />;
    return (
      <div>
        <NavBar
          style={{ backgroundColor: "#008cfe", color: "white" }}
          title="更多设置"
          left={
            <img
              className="left-img"
              src="./images/left.png"
              alt=""
              onClick={() =>
                this.setState({ isOk: true, isPath: RoutePath.device })
              }
            />
          }
        ></NavBar>
        <div
          style={{
            padding: "0 20px",
            height: 50,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
          }}
          onClick={() => this.setState({ _remoteAuth: true, _name: "" })}
        >
          <p
            style={{
              width: "calc(100vw - 120px)",
              color: "rgba(0,0,0,0.9)",
              padding: 0,
              margin: 0,
              fontSize: 16,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            设备名字
          </p>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <p
              style={{
                color: "rgba(0,0,0,0.4)",
                padding: 0,
                margin: 0,
                fontSize: 16,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {(_device && _device["aliasName"]) ||
                (_device &&
                  _device["product"] &&
                  _device["product"]["productName"]) ||
                "锁道智能锁"}
            </p>
            <img
              style={{ width: 20, heihgt: 20, marginLeft: 5 }}
              src="./images/right.png"
              alt=""
            />
          </div>
        </div>
        <div
          style={{
            padding: "0 20px",
            height: 50,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
          }}
        >
          <p
            style={{
              width: "calc(100vw - 120px)",
              color: "rgba(0,0,0,0.9)",
              padding: 0,
              margin: 0,
              fontSize: 16,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            设备ID
          </p>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <p
              style={{
                color: "rgba(0,0,0,0.4)",
                padding: 0,
                margin: 0,
                fontSize: 16,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {_device && _device["deviceMac"]}
            </p>
          </div>
        </div>
        <div
          style={{
            padding: "0 20px",
            height: 50,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
          }}
        >
          <p
            style={{
              width: "calc(100vw - 120px)",
              color: "rgba(0,0,0,0.9)",
              padding: 0,
              margin: 0,
              fontSize: 16,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            固件版本
          </p>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <p
              style={{
                color: "rgba(0,0,0,0.4)",
                padding: 0,
                margin: 0,
                fontSize: 16,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {_device && _device["firmwareVersion"]}
            </p>
          </div>
        </div>

        <div
          style={{
            padding: "0 20px",
            height: 50,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
          }}
        >
          <p
            style={{
              width: "calc(100vw - 120px)",
              color: "rgba(0,0,0,0.9)",
              padding: 0,
              margin: 0,
              fontSize: 16,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            开门推送
          </p>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Switch
              onColor={"#008cfe"}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(e) => this.changeSwitch(1)}
              checked={_device["openSwitch"] ? true : false}
            />
          </div>
        </div>
        <div
          style={{
            padding: "0 20px",
            height: 50,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
          }}
        >
          <p
            style={{
              width: "calc(100vw - 120px)",
              color: "rgba(0,0,0,0.9)",
              padding: 0,
              margin: 0,
              fontSize: 16,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            报警推送
          </p>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Switch
              onColor={"#008cfe"}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(e) => this.changeSwitch(2)}
              checked={_device["alertSwitch"] ? true : false}
            />
          </div>
        </div>
        <div
          style={{
            padding: "0 20px",
            height: 50,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
          }}
        >
          <p
            style={{
              width: "calc(100vw - 120px)",
              color: "rgba(0,0,0,0.9)",
              padding: 0,
              margin: 0,
              fontSize: 16,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            抓拍推送
          </p>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Switch
              onColor={"#008cfe"}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(e) => this.changeSwitch(3)}
              checked={_device["snapshotSwitch"] ? true : false}
            />
          </div>
        </div>

        <button
          style={{
            width: "80%",
            marginLeft: "10%",
            height: 40,
            textAlign: "center",
            backgroundColor: "#e5e5e5",
            borderRadius: 5,
            color: "rgba(255,0,0,0.9)",
            fontSize: 14,
            marginTop: 50,
            border: 0,
          }}
          onClick={() => {
            window.confirm("你确定删除该设备", () => {
              this.lockUnbindAdmin();
            });
          }}
        >
          删除设备
        </button>
        {this.renderModel()}
      </div>
    );
  }
  renderModel() {
    const { _remoteAuth } = this.state;

    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.4)",
          display: _remoteAuth ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          transition: "all 1s ease-out",
        }}
      >
        <div
          style={{
            width: "96%",
            marginTop: 50,
            backgroundColor: "white",
            borderRadius: 5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ color: "black" }}>修改设备名字</p>
          <div
            style={{
              width: "98%",
              height: 120,
              paddingLeft: 20,
              paddingRight: 20,
              marginBottom: 5,
              borderTop: "1px solid rgba(0,0,0,0.2)",
              borderBottom: "1px solid rgba(0,0,0,0.2)",
            }}
          >
            <div
              style={{
                marginTop: 10,
                height: 40,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                fontSize: 14,
              }}
            ></div>
            <input
              style={{
                width: "100%",
                padding: "5px",
                height: 40,
                border: "1px solid rgba(0,0,0,0.1)",
                backgroundColor: "transparent",
                borderRadius: 5,
              }}
              type="text"
              placeholder="输入设备别名"
              onChange={(e) => this.setState({ _name: e.currentTarget.value })}
            />
          </div>

          <div
            style={{
              width: "100%",
              height: 50,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "45%",
                height: 40,
                border: "1px solid rgba(0,0,0,0.3)",
                backgroundColor: "rgba(0,0,0,0.1)",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                borderRadius: 5,
                color: "red",
              }}
              onClick={() =>
                this.setState({ _remoteAuth: false, _remoteCode: "" })
              }
            >
              取消
            </div>
            <div
              style={{
                width: "45%",
                height: 40,
                border: "1px solid #008cfe",
                backgroundColor: "#008cfe",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                borderRadius: 5,
                color: "white",
              }}
              onClick={() => this.changeName()}
            >
              {`确认修改`}
            </div>
          </div>
          <div style={{ height: 10 }}></div>
        </div>
      </div>
    );
  }
}
