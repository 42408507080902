import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import NoPage from "../template/NoPage";
import Competence from "../template/Competence";
import Login from "../template/Login";
import Home from "../template/Home";
import Device from "../template/Device";
import User from "../template/User";
import About from "../template/About";
import Profile from "../template/Profile";
import Sign from "../template/Sign";
import Forget from "../template/Forget";
import ChangePassword from "../template/ChangePassword";

import Link1 from "../template/Link1";
import Link2 from "../template/Link2";
import Link3 from "../template/Link3";
import Link4 from "../template/Link4";

export default class RootRouter extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Competence} />
          <Route path="/index" component={Competence} />
          <Route path="/login" component={Login} />
          <Route path="/home" component={Home} />
          <Route path="/device" component={Device} />
          <Route path="/user" component={User} />
          <Route path="/about" component={About} />
          <Route path="/profile" component={Profile} />
          <Route path="/sign" component={Sign} />
          <Route path="/forget" component={Forget} />
          <Route path="/change" component={ChangePassword} />
          <Route path="/link1" component={Link1} />
          <Route path="/link2" component={Link2} />
          <Route path="/link3" component={Link3} />
          <Route path="/link4" component={Link4} />
          <Route component={NoPage} />
        </Switch>
      </Router>
    );
  }
}

export const RoutePath = {
  index: "/index",
  login: "/login",
  home: "/home",
  link1: "/link1",
  link2: "/link2",
  link3: "/link3",
  link4: "/link4",
  device: "/device",
  user: "/user",
  about: "/about",
  profile: "/profile",
  sign: "/sign",
  forget: "/forget",
  change: "/change",
};
