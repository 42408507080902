import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { API } from "../proxy/BaseHttp";

import { RoutePath } from "../proxy/RootRouter";
import { NavBar, ActionSheet } from "zarm";

export default class User extends Component {
  state = {
    isOk: false,
    isPath: false,
    userList: [],
    _moreAction: false,
    _select: false,
    _remoteAuth: false,
    _remoteName: "",
  };

  componentDidMount() {
    this.user = window.localStorage.getItem("user");
    if (this.user) this.user = JSON.parse(this.user);

    var device = window.localStorage.getItem("lock");
    if (device) {
      device = JSON.parse(device);
      this.lockKeyListAll(device["lock"]["expeedDeviceId"]);
    }
  }
  async lockKeyListAll(id) {
    try {
      var res = await API.lockKeyListAll(id);
      if (res && res.result_code === 0) {
        this.setState({ userList: res.data });
      }
    } catch (e) {}
  }
  async lockKeyDelete(item, index) {
    try {
      var res = await API.lockKeyDelete(item.id);
      if (res && res.result_code === 0) {
        const { userList } = this.state;
        var _userList = userList;
        item = { ...item, delFlag: 2 };
        _userList[index] = item;
        this.setState({
          userList: _userList,
          _moreAction: false,
          _select: false,
        });
      }
    } catch (e) {}
  }
  async lockKeySetHijack(item, index) {
    try {
      var res = await API.lockKeySetHijack(item.id, item.isHijack ? 0 : 1);
      if (res && res.result_code === 0) {
        const { userList } = this.state;
        var _userList = userList;
        item = { ...item, isHijack: item.isHijack ? 0 : 1 };
        _userList[index] = item;
        this.setState({
          userList: _userList,
          _moreAction: false,
          _select: false,
        });
      }
    } catch (e) {}
  }
  async changeName() {
    try {
      const { _select, _remoteName } = this.state;
      var res = await API.lockKeySetAlias(_select.item["id"], _remoteName);
      if (res && res.result_code === 0) {
        const { userList } = this.state;
        var _userList = userList;
        _select.item = { ..._select.item, aliasName: _remoteName };
        _userList[_select.index] = _select.item;
        this.setState({
          userList: _userList,
          _moreAction: false,
          _select: false,
          _remoteAuth: false,
          _remoteName: "",
        });
      }
    } catch (e) {}
  }

  render() {
    const { isOk, isPath, userList, _moreAction, _select } = this.state;
    if (isOk) return <Redirect to={{ pathname: isPath, state: {} }} />;
    return (
      <div>
        <NavBar
          style={{ backgroundColor: "#008cfe", color: "white" }}
          title="钥匙管理"
          left={
            <img
              className="left-img"
              src="./images/left.png"
              alt=""
              onClick={() =>
                this.setState({ isOk: true, isPath: RoutePath.device })
              }
            />
          }
        ></NavBar>
        {userList.length > 0
          ? this.renderDevice(userList)
          : this.renderNoDevice()}
        {this.renderModelPassword()}
        <ActionSheet
          visible={_moreAction}
          actions={[
            {
              text: "设置钥匙别名",
              onClick: () => {
                this.setState({ _remoteAuth: true, _moreAction: false });
              },
            },
            {
              text: "设置劫持钥匙",
              onClick: () => {
                this.lockKeySetHijack(_select.item, _select.index);
              },
            },
            {
              theme: "danger",
              text: "删除钥匙",
              onClick: () => {
                if (_select.index > 0) {
                  window.confirm("你确定要删除该用户吗？", () => {
                    this.lockKeyDelete(_select.item, _select.index);
                  });
                } else {
                  this.setState({ _moreAction: false });
                  window.alert("无法删除第一把钥匙");
                }
              },
            },
            {
              text: "取消",
              onClick: () => this.setState({ _moreAction: false }),
            },
          ]}
          onMaskClick={() => this.setState({ _moreAction: false })}
        />
      </div>
    );
  }
  renderDevice(list) {
    return (
      <div>
        {list.map((item, index) => {
          var openType = item["openType"];

          var title = "";
          if (openType === 0) {
            title = "指纹用户";
          } else if (openType === 1) {
            title = "密码用户";
          } else if (openType === 2) {
            title = "IC卡用户";
          } else {
            title = "未知";
          }

          return (
            <div
              key={`u-list-${index}`}
              style={{
                height: 100,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(0,0,0,0.1)",
              }}
            >
              <img
                style={{ width: 50, heihgt: 50, margin: "10px" }}
                src="./images/lock.png"
                alt=""
              />
              <div
                style={{ flex: 1 }}
                onClick={() => {
                  if (item.delFlag === 2) return;
                  this.setState({
                    _select: {
                      item: item,
                      index: index,
                    },
                    _moreAction: true,
                  });
                }}
              >
                <p
                  style={{
                    width: "calc(100vw - 120px)",
                    color: item["isHijack"] === 1 ? "red" : "rgba(0,0,0,0.9)",
                    padding: 0,
                    margin: 0,
                    height: 25,
                    fontSize: 16,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    textDecorationLine:
                      item["delFlag"] === 2 ? "line-through" : "none",
                  }}
                >
                  {`${item["aliasName"] || "未命名"} ${
                    item["isHijack"] === 1 ? "劫持钥匙" : ""
                  }`}
                </p>
                <p
                  style={{
                    color: "rgba(0,0,0,0.4)",
                    padding: 0,
                    margin: 0,
                    fontSize: 14,
                    textDecorationLine:
                      item["delFlag"] === 2 ? "line-through" : "none",
                  }}
                >
                  {`用户类型: ${title}`}
                </p>
                {item["keyIndex"] >= 0 ? (
                  <p
                    style={{
                      color: "rgba(0,0,0,0.4)",
                      padding: 0,
                      margin: 0,
                      fontSize: 14,
                      textDecorationLine:
                        item["delFlag"] === 2 ? "line-through" : "none",
                    }}
                  >
                    {`用户编号: ${item["keyIndex"]}`}
                  </p>
                ) : null}
              </div>
              {/* {index > 0 && item["delFlag"] !== 2 ? (
                <img
                  style={{ width: 30, heihgt: 30, margin: "5px 20px 5px 10px" }}
                  src="./images/delete.png"
                  alt=""
                  onClick={() => {
                    window.confirm("你确定要删除该用户吗？", () => {
                      this.lockKeyDelete(item, index);
                    });
                  }}
                />
              ) : null} */}
            </div>
          );
        })}
      </div>
    );
  }
  renderNoDevice() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p style={{ color: "#cdcdcd", fontSize: 14 }}>暂无数据</p>
      </div>
    );
  }
  renderModelPassword() {
    const { _remoteAuth, _remoteName } = this.state;

    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.4)",
          display: _remoteAuth ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          transition: "all 1s ease-out",
        }}
      >
        <div
          style={{
            width: "96%",
            marginTop: 50,
            backgroundColor: "white",
            borderRadius: 5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ color: "black" }}>修改钥匙别名</p>
          <div
            style={{
              width: "98%",
              height: 120,
              paddingLeft: 20,
              paddingRight: 20,
              marginBottom: 5,
              borderTop: "1px solid rgba(0,0,0,0.2)",
              borderBottom: "1px solid rgba(0,0,0,0.2)",
            }}
          >
            <div
              style={{
                marginTop: 10,
                height: 40,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                fontSize: 14,
              }}
            >
              {/* 输入安全密码授权操作 */}
            </div>
            <input
              style={{
                width: "100%",
                padding: "5px",
                height: 40,
                border: "1px solid rgba(0,0,0,0.1)",
                backgroundColor: "transparent",
                borderRadius: 5,
              }}
              type="text"
              placeholder="请输入钥匙别名"
              value={_remoteName}
              onChange={(e) =>
                this.setState({ _remoteName: e.currentTarget.value })
              }
            />
          </div>

          <div
            style={{
              width: "100%",
              height: 50,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "45%",
                height: 40,
                border: "1px solid rgba(0,0,0,0.3)",
                backgroundColor: "rgba(0,0,0,0.1)",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                borderRadius: 5,
                color: "red",
              }}
              onClick={() =>
                this.setState({ _remoteAuth: false, _remoteName: "" })
              }
            >
              取消
            </div>
            <div
              style={{
                width: "45%",
                height: 40,
                border: "1px solid #008cfe",
                backgroundColor: "#008cfe",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                borderRadius: 5,
                color: "white",
              }}
              onClick={() => this.changeName()}
            >
              {`修改`}
            </div>
          </div>
          <div style={{ height: 10 }}></div>
        </div>
      </div>
    );
  }
}
