import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { API } from "../proxy/BaseHttp";
import { NavBar } from "zarm";

import { RoutePath } from "../proxy/RootRouter";

class Sign extends Component {
  state = {
    isOk: false,
    isPath: false,
    username: "",
    code: "",
    password: "",
    _getCodeNum: 0,

    _step: 0,
  };
  componentWillUnmount() {
    this.t2 && clearInterval(this.t2);
  }
  async register() {
    const { username, password, code } = this.state;
    try {
      var res = await API.register(username, password, code);
      if (res && res.result_code === 0) {
        window.alert("注册成功");
        this.goBack();
      } else {
        if (res) window.alert(res["result_msg"]);
        else window.alert("注册失败");
      }
    } catch (e) {}
  }
  async sendSMS() {
    const { username } = this.state;
    try {
      this.setState({ _getCodeNum: 60 });
      this.t2 = setInterval(() => {
        const { _getCodeNum } = this.state;
        var index = _getCodeNum;
        if (index > 0) {
          index = index - 1;
          this.setState({ _getCodeNum: index });
        } else {
          this.t2 && clearInterval(this.t2);
          this.setState({ _getCodeNum: 0 });
        }
      }, 1000);
      var res = await API.sendSMS(username, 1);
      if (res && res.result_code === 0) {
      } else {
        window.alert(res["result_msg"] || "获取验证码失败");
        this.setState({ _getCodeNum: 0 });
      }
    } catch (e) {}
  }
  gotoCode() {
    const { username } = this.state;
    var telStr = /^[1]([3-9])[0-9]{9}$/;
    if (username === "" || !username) {
      window.alert("请输入手机号");
    } else if (!telStr.test(username)) {
      window.alert("请输入正确的手机号");
    } else {
      this.setState({ _step: 1 });
    }
  }
  gotoPassword() {
    const { code } = this.state;
    if (code === "" || !code) {
      window.alert("请输入验证码");
    } else {
      this.setState({ _step: 2 });
    }
  }
  gotoLogin() {
    const { password } = this.state;
    if (password === "" || !password) {
      window.alert("请输入密码");
    } else if (password.length < 6) {
      window.alert("密码不得少于6位");
    } else {
      this.register();
    }
  }
  goBack() {
    this.setState({ isOk: true, isPath: RoutePath.login });
  }

  render() {
    const { isOk, isPath, _step } = this.state;
    if (isOk) return <Redirect to={{ pathname: isPath, state: {} }} />;
    return (
      <div>
        {_step === 0 ? this.renderPhone() : null}
        {_step === 1 ? this.renderCode() : null}
        {_step === 2 ? this.renderPassword() : null}
      </div>
    );
  }
  renderPhone() {
    return (
      <div>
        <NavBar
          style={{ backgroundColor: "#008cfe", color: "white" }}
          title="账号注册"
          left={
            <img
              className="left-img"
              src="./images/left.png"
              alt=""
              onClick={() => this.goBack()}
            />
          }
        ></NavBar>

        <div
          style={{
            marginTop: 60,
            width: "100%",
            position: "relative",
            marginLeft: "10%",
          }}
        >
          <img
            src="./images/username.png"
            alt=""
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: 20,
              height: 20,
              margin: 10,
            }}
          />
          <input
            style={{
              width: "80%",
              padding: "5px 10px 5px 40px",
              height: 40,
              border: "1px solid rgba(0,0,0,0.1)",
              backgroundColor: "transparent",
              borderRadius: 5,
            }}
            placeholder="请输入手机"
            onChange={(e) => this.setState({ username: e.currentTarget.value })}
          />
        </div>
        <button
          style={{
            width: "80%",
            marginLeft: "10%",
            height: 40,
            textAlign: "center",
            backgroundColor: "#008cfe",
            borderRadius: 5,
            color: "white",
            fontSize: 14,
            marginTop: 30,
            border: 0,
          }}
          onClick={() => this.gotoCode()}
        >
          确定
        </button>
      </div>
    );
  }
  renderCode() {
    const { username, _getCodeNum } = this.state;
    return (
      <div>
        <NavBar
          style={{ backgroundColor: "#008cfe", color: "white" }}
          title="验证码"
          left={
            <img
              className="left-img"
              src="./images/left.png"
              alt=""
              onClick={() => this.setState({ _step: 0 })}
            />
          }
        ></NavBar>

        <div
          style={{
            width: "82%",
            marginLeft: "9%",
            marginTop: 60,
            color: "rgba(0,0,0,0.6)",
            fontWeight: "bold",
            padding: "10px 10px",
            display: "flex",
            flexDirection: "row",
            fontSize: 16,
          }}
        >
          接收验证码：{" "}
          <div style={{ color: "#008cfe", fontSize: 16 }}>{username}</div>
        </div>

        <div
          style={{
            marginTop: 10,
            width: "80%",
            position: "relative",
            marginLeft: "10%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <input
            style={{
              flex: 2,
              padding: "5px 10px 5px 5px",
              height: 40,
              border: "1px solid rgba(0,0,0,0.1)",
              backgroundColor: "transparent",
              borderRadius: 5,
            }}
            placeholder="请输入验证码"
            onChange={(e) => this.setState({ code: e.currentTarget.value })}
          />
          {_getCodeNum === 0 ? (
            <div
              style={{
                marginLeft: 10,
                flex: 1,
                height: 40,
                border: 0,
                backgroundColor: "#008cfe",
                borderRadius: 5,
                color: "white",
                fontSize: 14,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => this.sendSMS()}
            >
              发送
            </div>
          ) : (
            <div
              style={{
                marginLeft: 10,
                flex: 1,
                height: 40,
                border: "1px solid rgba(0,0,0,0.1)",
                backgroundColor: "rgba(0,0,0,0.1)",
                borderRadius: 5,
                color: "rgba(0,0,0,0.3)",
                fontSize: 14,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {`${_getCodeNum}秒`}
            </div>
          )}
        </div>
        <button
          style={{
            width: "80%",
            marginLeft: "10%",
            height: 40,
            textAlign: "center",
            backgroundColor: "#008cfe",
            borderRadius: 5,
            color: "white",
            fontSize: 14,
            marginTop: 30,
            border: 0,
          }}
          onClick={() => this.gotoPassword()}
        >
          确定
        </button>
      </div>
    );
  }
  renderPassword() {
    return (
      <div>
        <NavBar
          style={{ backgroundColor: "#008cfe", color: "white" }}
          title="新的密码"
          left={
            <img
              className="left-img"
              src="./images/left.png"
              alt=""
              onClick={() => this.setState({ _step: 1 })}
            />
          }
        ></NavBar>

        <div
          style={{
            width: "82%",
            marginLeft: "9%",
            marginTop: 60,
            color: "rgba(0,0,0,0.6)",
            fontWeight: "bold",
            padding: "10px 10px",
            display: "flex",
            flexDirection: "row",
            fontSize: 16,
          }}
        >
          输入新的账号登录密码
        </div>

        <div
          style={{
            marginTop: 10,
            width: "100%",
            position: "relative",
            marginLeft: "10%",
          }}
        >
          <img
            src="./images/password.png"
            alt=""
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: 20,
              height: 20,
              margin: 10,
            }}
          />
          <input
            style={{
              width: "80%",
              padding: "5px 10px 5px 40px",
              height: 40,
              border: "1px solid rgba(0,0,0,0.1)",
              backgroundColor: "transparent",
              borderRadius: 5,
            }}
            type="password"
            placeholder="请输入密码"
            onChange={(e) => this.setState({ password: e.currentTarget.value })}
          />
        </div>
        <button
          style={{
            width: "80%",
            marginLeft: "10%",
            height: 40,
            textAlign: "center",
            backgroundColor: "#008cfe",
            borderRadius: 5,
            color: "white",
            fontSize: 14,
            marginTop: 30,
            border: 0,
          }}
          onClick={() => this.gotoLogin()}
        >
          确定
        </button>
      </div>
    );
  }
}
export default Sign;
