import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { API } from "../proxy/BaseHttp";
import { NavBar } from "zarm";

import { RoutePath } from "../proxy/RootRouter";
import { DB } from "../proxy/BaseModal";

class Login extends Component {
  state = {
    isOk: false,
    isPath: false,
    username: "",
    password: "",
  };
  async appLogin() {
    const { username, password } = this.state;
    try {
      var code = DB.CODE({ type: "GET" });
      var res = await API.appLogin(username, password, false, code);
      if (res.result_code === 0) {
        if (res.data.user["openId"])
          window.localStorage.setItem("openid", res.data.user["openId"]);
        this.setState({ isOk: true, isPath: RoutePath.home });
      } else {
        window.alert(res.result_msg);
      }
    } catch (e) {}
  }
  render() {
    const { isOk, isPath } = this.state;
    if (isOk) return <Redirect to={{ pathname: isPath, state: {} }} />;
    return (
      <div>
        <NavBar
          style={{ backgroundColor: "#008cfe", color: "white" }}
          title="账号登录"
        ></NavBar>

        <div
          style={{
            width: 140,
            marginTop: 60,
            marginLeft: "calc(50vw - 80px)",
            textAlign: "center",
            color: "#008cfe",
            fontWeight: "bold",
            padding: "10px 10px",
            borderBottom: "2px solid #008cfe",
          }}
        >
          手机号登录
        </div>

        <div
          style={{
            marginTop: 30,
            width: "100%",
            position: "relative",
            marginLeft: "10%",
          }}
        >
          <img
            src="./images/username.png"
            alt=""
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: 20,
              height: 20,
              margin: 10,
            }}
          />
          <input
            style={{
              width: "80%",
              padding: "5px 10px 5px 40px",
              height: 40,
              border: "1px solid rgba(0,0,0,0.1)",
              backgroundColor: "transparent",
              borderRadius: 5,
            }}
            placeholder="请输入手机"
            onChange={(e) => this.setState({ username: e.currentTarget.value })}
          />
        </div>
        <div
          style={{
            marginTop: 10,
            width: "100%",
            position: "relative",
            marginLeft: "10%",
          }}
        >
          <img
            src="./images/password.png"
            alt=""
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: 20,
              height: 20,
              margin: 10,
            }}
          />
          <input
            style={{
              width: "80%",
              padding: "5px 10px 5px 40px",
              height: 40,
              border: "1px solid rgba(0,0,0,0.1)",
              backgroundColor: "transparent",
              borderRadius: 5,
            }}
            type="password"
            placeholder="请输入密码"
            onChange={(e) => this.setState({ password: e.currentTarget.value })}
          />
        </div>
        <button
          style={{
            width: "80%",
            marginLeft: "10%",
            height: 40,
            textAlign: "center",
            backgroundColor: "#008cfe",
            borderRadius: 5,
            color: "white",
            fontSize: 14,
            marginTop: 50,
            border: 0,
          }}
          onClick={() => this.appLogin()}
        >
          登录
        </button>
        <div
          style={{
            marginTop: 15,
            width: "80%",
            marginLeft: "10%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{ fontSize: 12, color: "#008cfe" }}
            onClick={() =>
              this.setState({ isOk: true, isPath: RoutePath.forget })
            }
          >
            忘记密码?
          </div>
          <div
            style={{ fontSize: 12, color: "#008cfe" }}
            onClick={() =>
              this.setState({ isOk: true, isPath: RoutePath.sign })
            }
          >
            账号注册
          </div>
        </div>
      </div>
    );
  }
}
export default Login;
