import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { API } from "../proxy/BaseHttp";

import { RoutePath } from "../proxy/RootRouter";
import { NavBar } from "zarm";

export default class Link3 extends Component {
  state = {
    isOk: false,
    isPath: false,
    deviceList: [{ a: 1 }],
    password: "",
  };

  componentDidMount() {
    this.user = window.localStorage.getItem("user");
    if (this.user) this.user = JSON.parse(this.user);
  }

  render() {
    const { isOk, isPath, deviceList } = this.state;
    if (isOk) return <Redirect to={{ pathname: isPath, state: {} }} />;
    return <div>{this.renderBindDevice()}</div>;
  }
  renderLoading() {
    return (
      <div>
        <NavBar
          style={{ backgroundColor: "#008cfe", color: "white" }}
          title="连接网络"
          left={<img className="left-img" src="./images/left.png" alt="" />}
        ></NavBar>
        <div className="competence-page">
          <img src="./images/loading.gif" alt="" />
          <div
            style={{
              padding: "30px 0 20px 0",
              color: "#1C89FF",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            检查网络连接...
          </div>
          <div style={{ padding: 0, color: "#3798FE", fontSize: 14 }}>
            剩余等待时间60秒
          </div>
        </div>
      </div>
    );
  }

  renderBindDevice() {
    return (
      <div>
        <NavBar
          style={{ backgroundColor: "#008cfe", color: "white" }}
          title="注册设备"
          left={<img className="left-img" src="./images/left.png" alt="" />}
        ></NavBar>
        <div className="competence-page">
          <img
            style={{
              width: 100,
              height: 100,
              padding: 20,
              border: "1px solid #008cfe",
              borderRadius: 200,
            }}
            src="./images/lock.png"
            alt=""
          />
          <div
            style={{
              padding: "30px 0 20px 0",
              color: "rgba(0,0,0,0.7)",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            智能门锁
          </div>
          <div style={{ padding: 0, color: "rgba(0,0,0,0.4)", fontSize: 14 }}>
            绑定成功
          </div>
          <button
            style={{
              width: "90%",
              marginLeft: "5%",
              height: 40,
              textAlign: "center",
              backgroundColor: "#008cfe",
              borderRadius: 5,
              color: "white",
              fontSize: 14,
              marginTop: 50,
              border: 0,
            }}
          >
            完成
          </button>
        </div>
      </div>
    );
  }
}
