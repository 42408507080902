import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { API } from "../proxy/BaseHttp";

import { RoutePath } from "../proxy/RootRouter";
import { NavBar } from "zarm";
import { DB } from "../proxy/BaseModal";

export default class Link2 extends Component {
  state = {
    isOk: false,
    isPath: false,
    deviceList: [],
    password: "",

    _selectItem: false,

    tipShow: false,
    tipMessage: "",
    loading: false,
    _readyIndex: 0,
    totoaTime: 60,
  };
  showTip(message) {
    this.setState({ tipShow: true, tipMessage: message });
    this.timer = setTimeout(() => {
      this.timer && clearTimeout(this.timer);
      this.setState({ tipShow: false, tipMessage: "" });
    }, 10000);
  }
  componentDidMount() {
    this.num = 0;
    var p = DB.PASSWORD({ type: "GET" });
    if (p !== "") this.setState({ password: p });

    this.getSSID();
    this.timer_1 = setInterval(() => {
      this.getSSID();
    }, 3000);
  }
  componentWillUnmount() {
    this.timer_1 && clearInterval(this.timer_1);
  }
  async getSSID() {
    try {
      var res = await API.getSSIDList();
      if (res && res["ssid"].length > 0) {
        this.timer_1 && clearInterval(this.timer_1);
        this.setState({ deviceList: res["ssid"], _readyIndex: 1 });
      } else {
        this.setState({ deviceList: [] });
      }
    } catch (e) {}
  }
  async goNext(ssid) {
    const { _selectItem, password } = this.state;
    if (password === null || password === "") {
      alert("返回检查WI-FI密码");
      return;
    }
    this.num++;
    this.setState({ loading: true });
    this.alertTime = setTimeout(() => {
      this.showTip("请检查关闭移动蜂窝网络");
    }, 3000);
    var res = await API.setSSID(ssid, password);
    if (res) {
      this.alertTime && clearTimeout(this.alertTime);
      this.autoRequest(res["macAddr"], res["deviceKey"]);
      this.setState({
        _readyIndex: 2,
        loading: false,
      });
      // window.alert(`${ssid} => ${password}`)
    } else {
      this.alertTime && clearTimeout(this.alertTime);
      if (this.num < 10) {
        this.goNext();
      } else {
        this.setState({ loading: false });
        alert(
          "与设备交互失败，请检查是否已经链接上设备的热点网络和关闭移动蜂窝网络"
        );
      }
    }
  }
  autoRequest(mac, deviceKey) {
    this.getAllow(mac, deviceKey);
    this.allowTime = setInterval(() => {
      const { totoaTime } = this.state;
      var t = totoaTime;
      t = t - 10;
      if (t >= 0) {
        this.getAllow(mac, deviceKey);
      } else {
        this.allowTime && clearInterval(this.allowTime);
        this.setState({ _readyIndex: 4 });
      }
      this.setState({ totoaTime: t });
    }, 10000);
  }
  async getAllow(mac, deviceKey) {
    try {
      var res = await API.lockGetByMac(mac, deviceKey);
      if (res && res.result_code === 0) {
        this.allowTime && clearInterval(this.allowTime);
        this.bindUser(res.data);
      }
    } catch (e) {}
  }
  async bindUser(deviceId) {
    try {
      var res = await API.lockBindAdmin(deviceId);
      if (res && res.result_code === 0) {
        this.setState({ _readyIndex: 3 });
      } else {
        alert(res.result_msg || "绑定失败");
        this.setState({ isOk: true, isPath: RoutePath.home });
      }
    } catch (e) {
      alert("绑定失败, 请重试");
    }
  }

  render() {
    const { isOk, isPath, _selectItem, _readyIndex } = this.state;
    if (isOk) return <Redirect to={{ pathname: isPath, state: {} }} />;
    return (
      <div>
        {_readyIndex === 0 ? this.renderReady() : null}
        {_readyIndex === 1 ? this.renderSelectPage() : null}
        {/* {_readyIndex === 1
          ? _selectItem
            ? this.renderInputPage()
            : this.renderSelectPage()
          : null} */}
        {_readyIndex === 2 ? this.renderLoading() : null}
        {_readyIndex === 3 ? this.renderBindDevice() : null}
        {_readyIndex === 4 ? this.renderError() : null}
      </div>
    );
  }
  renderReady() {
    return (
      <div>
        <NavBar
          style={{ backgroundColor: "#008cfe", color: "white" }}
          title="连接设备热点"
          left={
            <img
              className="left-img"
              src="./images/left.png"
              alt=""
              onClick={() =>
                this.setState({ isOk: true, isPath: RoutePath.link4 })
              }
            />
          }
        ></NavBar>
        <div
          style={{
            marginTop: 10,
            marginLeft: "5%",
            width: "90%",
            height: "auto",
            border: "1px solid rgba(0,0,0,0.1)",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.1)",
            borderRadius: 10,
          }}
        >
          <div
            style={{
              width: 50,
              color: "#128CFE",
              textAlign: "center",
            }}
          >
            1
          </div>
          <div
            style={{
              flex: 1,
              overflow: "auto",
              height: "auto",
              wordWrap: "break-word",
              backgroundColor: "white",
              padding: "10px 5px",
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
            }}
          >
            进入管理员界面，选择
            <b style={{ color: "green", fontWeight: "bold" }}>
              WIFI设置 --{">"} AP配置WIFI
            </b>
            ，具体方法请参考产品说明书。
          </div>
        </div>
        <div
          style={{
            marginTop: 10,
            marginLeft: "5%",
            width: "90%",
            height: "auto",
            border: "1px solid rgba(0,0,0,0.1)",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.1)",
            borderRadius: 10,
          }}
        >
          <div
            style={{
              width: 50,
              color: "#128CFE",
              textAlign: "center",
            }}
          >
            2
          </div>
          <div
            style={{
              flex: 1,
              overflow: "auto",
              height: "auto",
              wordWrap: "break-word",
              backgroundColor: "white",
              padding: "10px 5px",
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
            }}
          >
            回到手机桌面，找到并打开
            <b style={{ color: "green", fontWeight: "bold" }}>设置</b>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: 50, height: 50, margin: 10 }}
                src="./images/setting.jpg"
              />
            </div>
            找到并点击进入
            <b style={{ color: "green", fontWeight: "bold" }}>Wi-Fi</b>页面
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: 50, height: 50, margin: 10 }}
                src="./images/wifi.jpg"
              />
            </div>
            连接至以
            <b style={{ color: "green", fontWeight: "bold" }}>smartLock</b>
            开头的Wi-Fi
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "90%", margin: 15 }}
                src="./images/connect.jpeg"
              />
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: 10,
            marginLeft: "5%",
            width: "90%",
            height: "auto",
            border: "1px solid rgba(0,0,0,0.1)",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.1)",
            borderRadius: 10,
          }}
        >
          <div
            style={{
              width: 50,
              color: "#128CFE",
              textAlign: "center",
            }}
          >
            3
          </div>
          <div
            style={{
              flex: 1,
              overflow: "auto",
              height: "auto",
              wordWrap: "break-word",
              backgroundColor: "white",
              padding: "10px 5px",
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
            }}
          >
            连接之后返回该页面，等待页面自动跳转
          </div>
        </div>
        <div
          style={{
            marginTop: 10,
            marginLeft: "5%",
            width: "90%",
            textAlign: "center",
            color: "#B00000",
            fontWeight: "bold",
          }}
        >
          -----------注意事项-----------
          <div
            style={{
              textAlign: "left",
              padding: "10px 0 0 0",
              color: "#D84955",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            1.如果连接过程中，手机提示该热点无法上网，是否切换，请选择“不切换”或“继续使用”
          </div>
          <div
            style={{
              textAlign: "left",
              padding: "10px 0 0 0",
              color: "#D84955",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            2.如果连接之后，Wi-Fi会自动断开，或长时间停留在该页面，请关闭手机的4G网络后重试
          </div>
        </div>
      </div>
    );
  }
  renderSelectPage() {
    const { deviceList } = this.state;
    return (
      <div>
        <NavBar
          style={{ backgroundColor: "#008cfe", color: "white" }}
          title="选择Wi-Fi"
          left={
            <img
              className="left-img"
              src="./images/left.png"
              alt=""
              onClick={() => {
                this.setState({ isOk: true, isPath: RoutePath.home });
              }}
            />
          }
        ></NavBar>

        <div
          style={{
            height: 45,
            padding: 10,
            fontSize: 14,
            color: "rgba(0,0,0,0.7)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            border: "1px solid rgba(0,0,0,0.1)",
          }}
        >
          <div>{`附近总共扫描到${deviceList.length}个热点`}</div>
        </div>

        <div>
          {deviceList.map((item, index) => {
            return this.renderItem(item, index);
          })}
        </div>
      </div>
    );
  }
  renderItem(item, index) {
    return (
      <div
        key={`wifi-list-${index}`}
        style={{
          height: 45,
          padding: 10,
          fontSize: 14,
          color: "rgba(0,0,0,0.9)",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid rgba(0,0,0,0.1)",
        }}
        onClick={() => {
          this.setState({ _selectItem: item });
          setTimeout(() => {
            this.goNext(item);
          }, 1000);
        }}
      >
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {item}
        </div>
        <img
          style={{ width: 20, heihgt: 20 }}
          src="./images/password.png"
          alt=""
        />
      </div>
    );
  }
  renderInputPage() {
    const { loading, _selectItem } = this.state;
    return (
      <div>
        <NavBar
          style={{ backgroundColor: "#008cfe", color: "white" }}
          title="输入Wi-Fi密码"
          left={
            <img
              className="left-img"
              src="./images/left.png"
              alt=""
              onClick={() => {
                this.setState({ password: "", _selectItem: false });
              }}
            />
          }
        ></NavBar>

        <div
          style={{
            marginTop: 30,
            width: "90%",
            position: "relative",
            marginLeft: "5%",
            border: "1px solid #DDDDDD",
            borderRadius: 5,
          }}
        >
          <img
            src="./images/username.png"
            alt=""
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: 40,
              height: 40,
              padding: 10,
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
              backgroundColor: "#F5F5F5",
            }}
          />
          <input
            style={{
              width: "100%",
              padding: "5px 10px 5px 50px",
              height: 40,
              border: 0,
              backgroundColor: "transparent",
            }}
            value={_selectItem}
            disabled
          />
        </div>
        <div
          style={{
            marginTop: 10,
            width: "90%",
            position: "relative",
            marginLeft: "5%",
            border: "1px solid #DDDDDD",
            borderRadius: 5,
          }}
        >
          <img
            src="./images/password.png"
            alt=""
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: 40,
              height: 40,
              padding: 10,
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
              backgroundColor: "#F5F5F5",
            }}
          />
          <input
            style={{
              width: "100%",
              padding: "5px 10px 5px 50px",
              height: 40,
              border: 0,
              backgroundColor: "transparent",
            }}
            placeholder="请输入密码"
            type="password"
            onChange={(e) => this.setState({ password: e.currentTarget.value })}
          />
        </div>
        <button
          style={{
            width: "90%",
            marginLeft: "5%",
            height: 40,
            textAlign: "center",
            backgroundColor: "#008cfe",
            borderRadius: 5,
            color: "white",
            fontSize: 14,
            marginTop: 50,
            border: 0,
          }}
          disabled={loading}
          onClick={() => this.goNext()}
        >
          {loading ? (
            <img
              src="../assets/loading.gif"
              alt=""
              style={{ width: 20, height: 20 }}
            />
          ) : (
            "搜索设备"
          )}
        </button>
      </div>
    );
  }
  renderTip(tipShow, tipMessage) {
    return (
      <div className={`tip ${tipShow ? "tip-open" : ""}`}>{tipMessage}</div>
    );
  }

  renderLoading() {
    const { totoaTime } = this.state;
    return (
      <div>
        <NavBar
          style={{ backgroundColor: "#008cfe", color: "white" }}
          title="连接网络"
          left={
            <img
              className="left-img"
              src="./images/left.png"
              alt=""
              onClick={() => {
                this.setState({ isOk: true, isPath: RoutePath.home });
              }}
            />
          }
        ></NavBar>
        <div className="competence-page">
          <img src="./images/loading.gif" alt="" />
          <div
            style={{
              padding: "30px 0 20px 0",
              color: "#1C89FF",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            检查网络连接...
          </div>
          <div style={{ padding: 0, color: "#3798FE", fontSize: 14 }}>
            {`剩余等待时间${totoaTime}秒`}
          </div>
        </div>
      </div>
    );
  }
  renderBindDevice() {
    return (
      <div>
        <NavBar
          style={{ backgroundColor: "#008cfe", color: "white" }}
          title="注册设备"
          left={
            <img
              className="left-img"
              src="./images/left.png"
              alt=""
              onClick={() => {
                this.setState({ isOk: true, isPath: RoutePath.home });
              }}
            />
          }
        ></NavBar>
        <div className="competence-page">
          <img
            style={{
              width: 100,
              height: 100,
              padding: 20,
              border: "1px solid #008cfe",
              borderRadius: 200,
            }}
            src="./images/lock.png"
            alt=""
          />
          <div
            style={{
              padding: "30px 0 20px 0",
              color: "rgba(0,0,0,0.7)",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            智能门锁
          </div>
          <div style={{ padding: 0, color: "rgba(0,0,0,0.4)", fontSize: 14 }}>
            绑定成功
          </div>
          <button
            style={{
              width: "90%",
              marginLeft: "5%",
              height: 40,
              textAlign: "center",
              backgroundColor: "#008cfe",
              borderRadius: 5,
              color: "white",
              fontSize: 14,
              marginTop: 50,
              border: 0,
            }}
            onClick={() => {
              this.setState({ isOk: true, isPath: RoutePath.home });
            }}
          >
            完成
          </button>
        </div>
      </div>
    );
  }
  renderError() {
    return (
      <div>
        <NavBar
          style={{ backgroundColor: "#008cfe", color: "white" }}
          title="注册设备"
          left={
            <img
              className="left-img"
              src="./images/left.png"
              alt=""
              onClick={() => {
                this.setState({ isOk: true, isPath: RoutePath.home });
              }}
            />
          }
        ></NavBar>
        <div className="competence-page">
          <img
            style={{
              width: 100,
              height: 100,
              padding: 20,
              border: "1px solid #008cfe",
              borderRadius: 200,
            }}
            src="./images/lock.png"
            alt=""
          />
          <div
            style={{
              padding: "30px 0 20px 0",
              color: "rgba(0,0,0,0.7)",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            智能门锁
          </div>
          <div style={{ padding: 0, color: "rgba(0,0,0,0.4)", fontSize: 14 }}>
            绑定超时
          </div>
          <button
            style={{
              width: "90%",
              marginLeft: "5%",
              height: 40,
              textAlign: "center",
              backgroundColor: "#008cfe",
              borderRadius: 5,
              color: "white",
              fontSize: 14,
              marginTop: 50,
              border: 0,
            }}
            onClick={() => {
              this.setState({ isOk: true, isPath: RoutePath.home });
            }}
          >
            完成
          </button>
        </div>
      </div>
    );
  }
}
