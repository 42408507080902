import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { API } from "../proxy/BaseHttp";
import { RoutePath } from "../proxy/RootRouter";
import { DB } from "../proxy/BaseModal";

class Competence extends Component {
  state = {
    isPath: false,
  };
  constructor(props) {
    super(props);
    var url = props.location.search,
      theRequest = {};
    if (url.indexOf("?") !== -1) {
      var strs = url.substr(1).split("&");
      for (var i = 0; i < strs.length; i++)
        theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
    }
    if (theRequest.code === undefined) {
    } else {
      DB.CODE({ type: "SET", value: theRequest.code });
    }
    if (theRequest.d === undefined) {
    } else {
      DB.PUSHDATA({ type: "SET", value: { d: theRequest.d, t: theRequest.t } });
    }

    var u = window.localStorage.getItem("p");
    var p = window.localStorage.getItem("u");
    if (u && p) this.appLogin(u, p);
    else setTimeout(() => this.setState({ isPath: RoutePath.login }), 100);
  }
  async appLogin(u, p) {
    try {
      var code = DB.CODE({ type: "GET" });
      var res = await API.appLogin(u, p, true, code);
      if (res.result_code === 0) {
        var b = DB.PUSHDATA({ type: "GET" });
        if (b.d) {
          setTimeout(
            () => this.setState({ isOk: true, isPath: RoutePath.device }),
            100
          );
        } else {
          setTimeout(
            () => this.setState({ isOk: true, isPath: RoutePath.home }),
            100
          );
        }
      } else {
        setTimeout(() => this.setState({ isPath: RoutePath.login }), 100);
      }
    } catch (e) {}
  }

  render() {
    const { isPath } = this.state;

    if (isPath) {
      return <Redirect to={{ pathname: isPath, state: {} }} />;
    }
    return (
      <div className="competence-page">
        <img src="./images/loading.gif" alt="" />
      </div>
    );
  }
}
export default Competence;
