import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { API } from "../proxy/BaseHttp";

import { RoutePath } from "../proxy/RootRouter";
import { NavBar } from "zarm";

export default class Profile extends Component {
  state = {
    isOk: false,
    isPath: false,
  };
  componentDidMount() {
    this.user = window.localStorage.getItem("user");
    if (this.user) this.user = JSON.parse(this.user);
  }
  async appLogout() {
    try {
      window.confirm("你确定要退出登录？", () => {
        var openid = window.localStorage.getItem("openid");
        API.appLogout(openid);
        window.localStorage.removeItem("lock");
        window.localStorage.removeItem("p");
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("u");
        window.localStorage.removeItem("user");
        window.localStorage.removeItem("openid");
        setTimeout(() => {
          this.setState({ isOk: true, isPath: RoutePath.index });
        }, 100);
      });
    } catch (e) {}
  }
  render() {
    const { isOk, isPath } = this.state;
    if (isOk) return <Redirect to={{ pathname: isPath, state: {} }} />;
    return (
      <div>
        <NavBar
          style={{ backgroundColor: "#008cfe", color: "white" }}
          title="更多设置"
          left={
            <img
              className="left-img"
              src="./images/left.png"
              alt=""
              onClick={() =>
                this.setState({ isOk: true, isPath: RoutePath.home })
              }
            />
          }
        ></NavBar>
        <div
          style={{
            padding: "0 20px",
            height: 50,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
          }}
          onClick={() => this.setState({ isOk: true, isPath: RoutePath.home })}
        >
          <p
            style={{
              width: "calc(100vw - 120px)",
              color: "rgba(0,0,0,0.9)",
              padding: 0,
              margin: 0,
              fontSize: 16,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            我的设备
          </p>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: 20, heihgt: 20, marginLeft: 5 }}
              src="./images/right.png"
              alt=""
            />
          </div>
        </div>
        <div
          style={{
            padding: "0 20px",
            height: 50,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
          }}
          onClick={() =>
            this.setState({ isOk: true, isPath: RoutePath.change })
          }
        >
          <p
            style={{
              width: "calc(100vw - 120px)",
              color: "rgba(0,0,0,0.9)",
              padding: 0,
              margin: 0,
              fontSize: 16,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            修改密码
          </p>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: 20, heihgt: 20, marginLeft: 5 }}
              src="./images/right.png"
              alt=""
            />
          </div>
        </div>

        <button
          style={{
            width: "80%",
            marginLeft: "10%",
            height: 40,
            textAlign: "center",
            backgroundColor: "#e5e5e5",
            borderRadius: 5,
            color: "rgba(255,0,0,0.9)",
            fontSize: 14,
            marginTop: 50,
            border: 0,
          }}
          onClick={() => {
            this.appLogout();
          }}
        >
          退出登录
        </button>
      </div>
    );
  }
}
