import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { API } from "../proxy/BaseHttp";

import { RoutePath } from "../proxy/RootRouter";
import { NavBar } from "zarm";

export default class Home extends Component {
  state = {
    isOk: false,
    isPath: false,
    deviceList: [],
  };

  componentDidMount() {
    this.user = window.localStorage.getItem("user");
    if (this.user) this.user = JSON.parse(this.user);

    this.getDeviceList();
  }
  async getDeviceList() {
    try {
      var res = await API.lockList();
      if (res && res.result_code === 0) {
        this.setState({ deviceList: res.data["list"] });
      }
    } catch (e) { }
  }

  render() {
    const { isOk, isPath, deviceList } = this.state;
    if (isOk) return <Redirect to={{ pathname: isPath, state: {} }} />;
    return (
      <div>
        <NavBar
          style={{ backgroundColor: "#008cfe", color: "white" }}
          title=""
          left={
            <div
              className="left-view"
              onClick={() =>
                this.setState({ isOk: true, isPath: RoutePath.profile })
              }
            >
              菜单
            </div>
          }
          right={
            <div
              className="right-view"
              onClick={() =>
                this.setState({ isOk: true, isPath: RoutePath.link4 })
              }
            >
              添加
            </div>
          }
        ></NavBar>
        {deviceList.length > 0
          ? this.renderDevice(deviceList)
          : this.renderNoDevice()}
      </div>
    );
  }
  renderDevice(list) {
    return (
      <div>
        {list.map((item, index) => {
          var title = "锁道智能锁";

          if (item["lock"]["aliasName"]) {
            title = item["lock"]["aliasName"];
          } else if (item["product"]) {
            if (item["product"]["productName"])
              title = item["product"]["productName"];
          }

          return (
            <div
              key={`d-list-${index}`}
              style={{
                height: 100,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(0,0,0,0.1)",
              }}
              onClick={() => {
                window.localStorage.setItem("lock", JSON.stringify(item));
                setTimeout(() => {
                  this.setState({ isOk: true, isPath: RoutePath.device });
                }, 100);
              }}
            >
              <img
                style={{ width: 50, heihgt: 50, margin: "10px" }}
                src="./images/lock.png"
                alt=""
              />
              <div style={{ flex: 1 }}>
                <p
                  style={{
                    width: "calc(100vw - 120px)",
                    color: "rgba(0,0,0,0.9)",
                    padding: 0,
                    margin: 0,
                    height: 25,
                    fontSize: 16,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {title}
                </p>
                <p
                  style={{
                    color: "rgba(0,0,0,0.4)",
                    padding: 0,
                    margin: 0,
                    fontSize: 14,
                  }}
                >
                  {`设备ID: ${item["lock"]["expeedDeviceId"]}`}
                </p>
              </div>
              {item["isDefault"] ? (
                <p
                  style={{
                    width: 40,
                    heihgt: 20,
                    fontSize: 14,
                    marginRight: 10,
                  }}
                >
                  置顶
                </p>
              ) : (
                <p
                  style={{
                    width: 40,
                    heihgt: 20,
                    fontSize: 14,
                    marginRight: 10,
                  }}
                ></p>
              )}
            </div>
          );
        })}
      </div>
    );
  }
  renderNoDevice() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          style={{ width: 100, height: 100, marginTop: 50 }}
          src="./images/empty.png"
          alt=""
        />
        <p style={{ color: "#cdcdcd", fontSize: 14 }}>暂无数据</p>

        <div
          style={{
            marginTop: 50,
            textAlign: "center",
            border: "1px dashed #cdcdcd",
            padding: "10px 20px",
            borderRadius: 10,
            fontSize: 16,
          }}
          onClick={() => this.setState({ isOk: true, isPath: RoutePath.link4 })}
        >
          {" "}
          + 添加设备
        </div>
      </div>
    );
  }
}
