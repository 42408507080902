import MD5 from "crypto-js/md5";
// const URL = "http://lock.iot-expeed.com:8083/api";
// const URL = "https://dev.lock.iot-expeed.com";
const URL = "https://dev.lock.expeedos.cn";


export const API = {
  x_www_form_urlencoded(object) {
    let data = "";
    for (var i in object) {
      if (object[i] !== undefined) {
        data += `${i}=${object[i]}&`;
      }
    }
    return data;
  },
  sendPOST(url, body) {
    var token = window.localStorage.getItem("token");
    console.log(url, body, token);
    return fetch(`${URL}${url}?${this.x_www_form_urlencoded(body)}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        token: token ? token : null,
      },
    })
      .then((Response) => Response.json())
      .then((ResponseJson) => {
        console.log("ResponseJson=> ", ResponseJson);

        if (ResponseJson.result_code === 403) {
          var u = window.localStorage.getItem("p");
          var p = window.localStorage.getItem("u");
          this.appLogin(u, p, true);
        }
        return ResponseJson;
      })
      .catch((error) => {
        console.log("error=> ", error);
        return false;
      });
  },
  sendGET(url, body) {
    var token = window.localStorage.getItem("token");
    console.log(url, body, token);
    return fetch(`${URL}${url}?${this.x_www_form_urlencoded(body)}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        token: token ? token : null,
      },
    })
      .then((Response) => Response.json())
      .then((ResponseJson) => {
        console.log("ResponseJson=> ", ResponseJson);

        if (ResponseJson.result_code === 403) {
          var u = window.localStorage.getItem("p");
          var p = window.localStorage.getItem("u");
          this.appLogin(u, p, true);
        }

        return ResponseJson;
      })
      .catch((error) => {
        console.log("error=> ", error);
        return false;
      });
  },

  appLogin(username, password, isMd5, clientId) {
    var body = { username: username, password: MD5(password), model: 101 };
    if (isMd5) body["password"] = password;

    if (clientId) body["clientId"] = clientId;
    window.localStorage.setItem("p", username);
    window.localStorage.setItem("u", isMd5 ? password : MD5(password));

    console.log("/appLogin", body);
    return fetch(`${URL}/appLogin?${this.x_www_form_urlencoded(body)}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((Response) => Response.json())
      .then((ResponseJson) => {
        console.log("ResponseJson=> ", ResponseJson);
        if (ResponseJson.result_code === 0) {
          window.localStorage.setItem("token", ResponseJson.data.token);
          window.localStorage.setItem(
            "user",
            JSON.stringify(ResponseJson.data.user)
          );
        }
        return ResponseJson;
      })
      .catch((error) => {
        console.log("error=> ", error);
        return false;
      });
  },
  register(username, password, smsCode) {
    return this.sendPOST("/register", {
      username: username,
      password: MD5(password),
      smsCode: smsCode,
    });
  },
  sendSMS(phone, needRegister) {
    return this.sendPOST("/sendSMS", {
      phone: phone,
      needRegister: needRegister,
    });
  },
  resetPasswd(username, password, smsCode) {
    return this.sendPOST("/resetPasswd", {
      username: username,
      password: MD5(password),
      smsCode: smsCode,
    });
  },
  updatePasswd(p1, p2) {
    return this.sendPOST("/updatePasswd", {
      oldPasswd: MD5(p1),
      newPasswd: MD5(p2),
    });
  },
  lockGetByMac(deviceMac, deviceKey) {
    var token = window.localStorage.getItem("token");
    return fetch(
      `${URL}/lock/getByMac?${this.x_www_form_urlencoded({
        deviceMac: deviceMac,
        deviceKey: deviceKey,
      })}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          token: token ? token : null,
        },
      }
    )
      .then((Response) => Response.json())
      .then((ResponseJson) => {
        console.log("ResponseJson=> ", ResponseJson);
        return ResponseJson;
      })
      .catch((error) => {
        console.log("error=> ", error);
        return false;
      });
  },
  lockBindAdmin(deviceId) {
    return this.sendPOST("/lock/bindAdmin", { deviceId: deviceId });
  },
  lockList() {
    return this.sendGET("/lock/list", { pageNum: 1, pageSize: 50 });
  },
  lockOne(deviceId) {
    return this.sendGET("/lock/one", { deviceId: deviceId });
  },
  histOpen(deviceId, start, end, boundaryId) {
    var body = { deviceId: deviceId, start: start, end: end, pageSize: 100 };
    if (boundaryId) {
      body["boundaryId"] = boundaryId;
      body["direction"] = 1;
    }
    return this.sendGET("/hist/open", body);
  },
  histAlert(deviceId, start, end, boundaryId) {
    var body = { deviceId: deviceId, start: start, end: end, pageSize: 100 };
    if (boundaryId) {
      body["boundaryId"] = boundaryId;
      body["direction"] = 1;
    }
    return this.sendGET("/hist/alert", body);
  },
  firmwarePictureSearch(deviceId, start, end, boundaryId) {
    var body = { deviceId: deviceId, start: start, end: end, pageSize: 100 };
    if (boundaryId) {
      body["boundaryId"] = boundaryId;
      body["direction"] = 1;
    }
    return this.sendGET("/firmware/picture/search", body);
  },
  lockKeyListAll(deviceId) {
    return this.sendGET("/lock/key/listAll", { deviceId: deviceId });
  },
  lockKeyDelete(keyId) {
    return this.sendPOST("/lock/key/delete", {
      keyId: keyId,
    });
  },
  lockUnbindAdmin(deviceId) {
    return this.sendPOST("/lock/unbindAdmin", {
      deviceId: deviceId,
    });
  },
  histRecent(deviceId) {
    return this.sendGET("/hist/recent", {
      deviceId: deviceId,
    });
  },
  lockControlRemoteAuth(deviceId, value, password) {
    return this.sendPOST("/lock/control/remoteAuth", {
      deviceId: deviceId,
      value: value,
      password: password,
    });
  },
  lockSetDefault(deviceId) {
    return this.sendPOST("/lock/setDefault", { deviceId: deviceId });
  },
  lockSetAlias(deviceId, alias) {
    return this.sendPOST("/lock/setAlias", {
      deviceId: deviceId,
      alias: alias,
    });
  },
  lockUpdateSwitch(body) {
    return this.sendPOST("/lock/updateSwitch", body);
  },
  lockGetSwitch(deviceId) {
    return this.sendGET("/lock/getSwitch", { deviceId: deviceId });
  },

  getSSIDList() {
    return fetch("http://192.168.5.1/getApList", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("request ", result);
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        return false;
      });
  },
  setSSID(ssid, password) {
    return fetch(`http://192.168.5.1/setAp?ssid=${ssid}&pswd=${password}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("request ", result);
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        return false;
      });
  },
  lockKeySetHijack(keyId, isHijack) {
    return this.sendPOST("/lock/key/setHijack", {
      keyId: keyId,
      isHijack: isHijack,
    });
  },
  lockKeySetAlias(keyId, alias) {
    return this.sendPOST("/lock/key/setAlias", { keyId: keyId, alias: alias });
  },
  appLogout(clientId) {
    return this.sendPOST("/appLogout", { clientId: clientId });
  },
};

//{"ssid":["NBICT_15F","此广告位常年招商","lianshengkeji","zlj","Expeed_1306","JDCwifi_0CBC","Huawei","NBICC_ZT","rsvp_guest","TP-LINK_427F","DIRECT-LODESKTOP-9Q3U2TKmsOI"],"macAddr":"8caab556ca05"}
//{"macAddr":"8caab556ca05","deviceKey":"fd8cc77ef60b4f039ec643bf58214f77","ssid":"NBICC_ZT","pswd":"1234567890"}
