var db = {
  CODE: "",
  DATA: {},
  PASSWORD: ''
};

export const DB = {
  CODE(action) {
    return this.SearchFunc(
      action,
      (value) => {
        db.CODE = action.value;
      },
      () => {
        return db.CODE;
      }
    );
  },
  PUSHDATA(action) {
    return this.SearchFunc(
      action,
      (value) => {
        db.DATA = action.value;
      },
      () => {
        return db.DATA;
      }
    );
  },
  PASSWORD(action) {
    return this.SearchFunc(
      action,
      (value) => {
        db.PASSWORD = action.value;
      },
      () => {
        return db.PASSWORD;
      }
    );
  },
  SearchFunc(action, input, output) {
    switch (action.type) {
      case "SET":
        input(action.value);
        break;
      case "GET":
        return output();
      default:
        return undefined;
    }
  },
};

window.DB = DB;
