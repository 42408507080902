import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { API } from "../proxy/BaseHttp";
import { DB } from "../proxy/BaseModal";

import { RoutePath } from "../proxy/RootRouter";
import { NavBar } from "zarm";
import { Tabs, ActionSheet, Cell, Button } from "zarm";

const { Panel } = Tabs;

export default class Device extends Component {
  state = {
    isOk: false,
    isPath: false,
    _lock: false,

    _list1: [],
    _list2: [],
    _list3: [],

    _selectItem: false,
    _lockTime: 0,
    _remoteAuth: false,
    _remoteCode: "",

    _moreAction: false,
    _showNotice: [],
    _tabValue: 0,
  };

  componentDidMount() {
    this.user = window.localStorage.getItem("user");
    if (this.user) this.user = JSON.parse(this.user);

    var device = window.localStorage.getItem("lock");
    var b = DB.PUSHDATA({ type: "GET" });
    var device = window.localStorage.getItem("lock");
    if (b.d) {
      this.getLockInfo(b.d);
      this.histRecent(b.d);

      var t = b.t || 0;
      if (t == 2) {
        t = 0;
        this.histOpen(b.d);
      } else if (t == 1) {
        t = 1;
        this.histAlert(b.d);
      } else if (t == 3) {
        t = 2;
        this.firmwarePictureSearch(b.d);
      }
      this.setState({ _tabValue: t });
    } else if (device) {
      device = JSON.parse(device);
      this.getLockInfo(device["lock"]["expeedDeviceId"]);
      this.histRecent(device["lock"]["expeedDeviceId"]);
    }

    var time1 = new Date();
    var time2 = new Date(time1 - 1000 * 60 * 60 * 24 * 30);
    this.start = time1.format("yyyy-MM-dd");
    this.end = time2.format("yyyy-MM-dd");
  }
  componentWillUnmount() {
    this.t1 && clearTimeout(this.t1);
    this._showNoticeTime && clearTimeout(this._showNoticeTime);
  }
  async getLockInfo(id) {
    try {
      var res = await API.lockOne(id);
      if (res && res.result_code === 0) {
        this.histOpen(res.data["expeedDeviceId"]);

        var lockType = "";
        if (res.data["expeedDeviceId"]) {
          lockType = res.data["expeedDeviceId"].substr(0, 4);
        }

        this.setState({ _lock: { lock_t: lockType, ...res.data } });
      }
    } catch (e) { }
  }

  async histOpen(id, boundaryId) {
    try {
      var res = await API.histOpen(id, this.end, this.start, boundaryId);
      if (res && res.data.list.length > 0) {
        this.setState({ _list1: res.data.list });
      }
    } catch (e) { }
  }
  async histAlert(id, boundaryId) {
    try {
      var res = await API.histAlert(id, this.end, this.start, boundaryId);
      if (res && res.data.list.length > 0) {
        this.setState({ _list2: res.data.list });
      }
    } catch (e) { }
  }
  async firmwarePictureSearch(id, boundaryId) {
    try {
      var res = await API.firmwarePictureSearch(
        id,
        this.end,
        this.start,
        boundaryId
      );
      if (res && res.data.list.length > 0) {
        this.setState({ _list3: res.data.list });
      }
    } catch (e) { }
  }

  async histRecent(id) {
    try {
      var res = await API.histRecent(id);
      this.t1 = setTimeout(() => {
        this.histRecent(id);
      }, 5000);
      if (res && res.result_code === 0) {
        if (res.data.length > 0) {
          this._showNoticeTime && clearTimeout(this._showNoticeTime);

          this.setState({ _showNotice: res.data });
          this._showNoticeTime = setTimeout(() => {
            this.setState({ _showNotice: false });
          }, 60000);
        }
      }
    } catch (e) { }
  }
  _parseTime(t1) {
    setTimeout(() => {
      const { _selectItem } = this.state;
      var newTime = new Date().getTime();
      var t = parseInt(newTime - t1) / 1000;
      if (60 - t > 0 && _selectItem) {
        this.setState({ _lockTime: Math.floor(60 - t) });
        this._parseTime(t1);
      } else {
        this.setState({ _lockTime: 0 });
      }
    }, 1000);
  }

  async setDefault() {
    try {
      const { _lock } = this.state;
      var res = await API.lockSetDefault(_lock["expeedDeviceId"]);
      if (res) {
        this.setState({ _moreAction: false });
        window.alert(res.result_msg);
      }
    } catch (e) { }
  }

  async lockControlRemoteAuth(deviceId, value, password) {
    try {
      var res = await API.lockControlRemoteAuth(deviceId, value, password);
      if (res && res.result_code === 0) {
        this.setState({
          _selectItem: false,
          _remoteAuth: false,
          _remoteCode: "",
        });
        setTimeout(() => window.alert("远程授权下发成功"), 100);
      } else {
        if (res) window.alert(res.result_msg);
        else window.alert("远程授权下发失败");
      }
    } catch (e) { }
  }
  remoteOpen() {
    const { _lock, _remoteCode } = this.state;
    console.log(_lock["expeedDeviceId"], 1, _remoteCode, isNaN(_remoteCode));
    if (_remoteCode.length < 6) {
      window.alert("安全密码长度有误");
    } else if (!isNaN(_remoteCode)) {
      this.lockControlRemoteAuth(_lock["expeedDeviceId"], 1, _remoteCode);
    } else {
      window.alert("安全密码有误");
    }
  }

  render() {
    const { isOk, isPath, _moreAction, _lock } = this.state;

    if (isOk) return <Redirect to={{ pathname: isPath, state: {} }} />;
    return (
      <div>
        <NavBar
          style={{ backgroundColor: "#008cfe", color: "white" }}
          title="智能门锁"
          left={
            <img
              className="left-img"
              src="./images/left.png"
              alt=""
              onClick={() => {
                DB.PUSHDATA({ type: "SET", value: {} });
                this.setState({ isOk: true, isPath: RoutePath.home });
              }}
            />
          }
          right={
            <div
              className="right-view"
              onClick={() => this.setState({ _moreAction: true })}
            >
              更多
            </div>
          }
        ></NavBar>
        <div
          style={{
            width: "100%",
            height: 180,
            backgroundColor: "#008cfe",
            color: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              width: 104,
              height: 104,
              padding: 30,
              borderRadius: 64,
              border: "1px solid white",
            }}
            src="./images/kaisuo.png"
            alt=""
          />
          {/* <p>门锁已打开</p> */}
          <div
            style={{
              marginTop: 10,
              width: "94%",
              height: 30,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {_lock["power2"] !== 7 ? (
              <img
                style={{
                  width: 24,
                  height: 24,
                }}
                src={`./images/${_lock["power2"]}.png`}
                alt=""
              />
            ) : (
              <div
                style={{
                  width: 24,
                  height: 24,
                }}
              />
            )}
            {_lock["power"] !== 7 ? (
              <img
                style={{
                  width: 24,
                  height: 24,
                }}
                src={`./images/${_lock["power"]}.png`}
                alt=""
              />
            ) : (
              <div
                style={{
                  width: 24,
                  height: 24,
                }}
              />
            )}
          </div>
        </div>
        {(_lock && _lock["lock_t"] === "02C8") ||
          (_lock && _lock["lock_t"] === "02AF")
          ? this.renderType1()
          : this.renderType2()}
        {this.renderNotice()}
        {this.renderModel()}
        {this.renderModelPassword()}
        <ActionSheet
          visible={_moreAction}
          actions={[
            {
              text: "钥匙管理",
              onClick: () => {
                this.setState({ isOk: true, isPath: RoutePath.user });
              },
            },
            {
              text: "置顶",
              onClick: () => this.setDefault(),
            },
            {
              text: "更多设置",
              onClick: () => {
                window.localStorage.setItem("lock2", JSON.stringify(_lock));
                this.setState({ isOk: true, isPath: RoutePath.about });
              },
            },
            {
              theme: "danger",
              text: "取消",
              onClick: () => this.setState({ _moreAction: false }),
            },
          ]}
          onMaskClick={() => this.setState({ _moreAction: false })}
        />
      </div>
    );
  }
  renderType1() {
    const { _list1, _list2, _list3, _tabValue } = this.state;
    return (
      <Tabs
        value={_tabValue}
        onChange={(index) => {
          const { _lock } = this.state;
          if (index === 0) this.histOpen(_lock["expeedDeviceId"]);
          else if (index === 1) this.histAlert(_lock["expeedDeviceId"]);
          else if (index === 2)
            this.firmwarePictureSearch(_lock["expeedDeviceId"]);
          this.setState({ _tabValue: index });
        }}
      >
        <Panel title="开门记录">
          <div>
            {_list1.length > 0 ? (
              _list1.map((item, index) => {
                return this.renderItem1(item, index);
              })
            ) : (
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "rgba(0,0,0,0.3)",
                }}
              >
                暂无数据
              </p>
            )}
          </div>
        </Panel>
        <Panel title="安全报警">
          <div>
            {_list2.length > 0 ? (
              _list2.map((item, index) => {
                return this.renderItem2(item, index);
              })
            ) : (
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "rgba(0,0,0,0.3)",
                }}
              >
                暂无数据
              </p>
            )}
          </div>
        </Panel>

        <Panel title="抓拍信息">
          <div>
            {_list3.length > 0 ? (
              _list3.map((item, index) => {
                return this.renderItem3(item, index);
              })
            ) : (
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "rgba(0,0,0,0.3)",
                }}
              >
                暂无数据
              </p>
            )}
          </div>
        </Panel>
      </Tabs>
    );
  }
  renderType2() {
    const { _list1, _list2, _tabValue } = this.state;
    return (
      <Tabs
        value={_tabValue}
        onChange={(index) => {
          const { _lock } = this.state;
          if (index === 0) this.histOpen(_lock["expeedDeviceId"]);
          else if (index === 1) this.histAlert(_lock["expeedDeviceId"]);
          else if (index === 2)
            this.firmwarePictureSearch(_lock["expeedDeviceId"]);
          this.setState({ _tabValue: index });
        }}
      >
        <Panel title="开门记录">
          <div>
            {_list1.length > 0 ? (
              _list1.map((item, index) => {
                return this.renderItem1(item, index);
              })
            ) : (
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "rgba(0,0,0,0.3)",
                }}
              >
                暂无数据
              </p>
            )}
          </div>
        </Panel>
        <Panel title="安全报警">
          <div>
            {_list2.length > 0 ? (
              _list2.map((item, index) => {
                return this.renderItem2(item, index);
              })
            ) : (
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "rgba(0,0,0,0.3)",
                }}
              >
                暂无数据
              </p>
            )}
          </div>
        </Panel>
      </Tabs>
    );
  }

  renderItem1(item, index) {
    var openType = item["openType"];

    var img = "./images/kaisuo.png",
      title = "";
    if (openType === 0) {
      title = "指纹";
    } else if (openType === 1) {
      title = "密码";
    } else if (openType === 2) {
      title = "IC卡";
    } else if (openType === 3) {
      title = "APP临时密码";
    } else if (openType === 4) {
      title = "APP远程开锁";
    } else if (openType === 5) {
      title = "默认密码";
    } else if (openType === 7) {
      title = "人脸识别";
    } else {
      title = "未知";
    }

    if (item["key"] && item["key"]["keyIndex"] >= 0) {
      title = title + "用户: " + item["key"]["keyIndex"];
    }

    return (
      <div
        key={`item1-${index}`}
        style={{
          borderBottom: "1px solid rgba(0,0,0,0.1)",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <img
          style={{
            height: 50,
            width: 50,
            backgroundColor: "#008cfe",
            borderRadius: 50,
            padding: 10,
            margin: 10,
          }}
          src={img}
          alt=""
        />
        <div style={{ flex: 1 }}>
          <div style={{ fontSize: 14, paddingTop: 5, paddingBottom: 2 }}>
            门锁已打开
          </div>
          <div
            style={{
              fontSize: 12,
              color: "rgba(0,0,0, 0.3)",
              paddingTop: 2,
              paddingBottom: 2,
            }}
          >
            {title}
          </div>
          <div
            style={{
              fontSize: 12,
              color: "rgba(0,0,0, 0.3)",
              paddingTop: 2,
              paddingBottom: 5,
            }}
          >
            {item["openTime"] &&
              new Date(item["openTime"]).format("yyyy年MM月dd日 hh:mm:ss")}
          </div>
        </div>
      </div>
    );
  }
  renderItem2(item, index) {
    var alertType = item["alertType"];

    var img = "./images/kaisuo.png",
      title = "";
    if (alertType === 0) {
      title = "撬锁报警";
    } else if (alertType === 1) {
      title = "多次尝试密码报警";
    } else if (alertType === 2) {
      title = "多次尝试指纹报警";
    } else if (alertType === 3) {
      title = "多次尝试磁卡报警";
    } else if (alertType === 4) {
      title = "低电量报警";
    } else if (alertType === 5) {
      title = "关锁异常报警";
    } else if (alertType === 6) {
      title = "进入管理员菜单";
    } else if (alertType === 7) {
      title = "触发劫持报警";
    } else if (alertType === 8) {
      title = "门铃提醒";
    } else if (alertType === 9) {
      title = "人脸识别报警";
    } else if (alertType === 10) {
      title = "徘徊报警";
    } else {
      title = "未知";
    }

    return (
      <div
        key={`item2-${index}`}
        style={{
          borderBottom: "1px solid rgba(0,0,0,0.1)",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <img
          style={{
            height: 50,
            width: 50,
            backgroundColor: "#008cfe",
            borderRadius: 50,
            padding: 10,
            margin: 10,
          }}
          src={img}
          alt=""
        />
        <div style={{ flex: 1 }}>
          <div style={{ fontSize: 14, paddingTop: 5, paddingBottom: 2 }}>
            {title}
          </div>
          <div
            style={{
              fontSize: 12,
              color: "rgba(0,0,0, 0.3)",
              paddingTop: 2,
              paddingBottom: 5,
            }}
          >
            {item["alertTime"] &&
              new Date(item["alertTime"]).format("yyyy年MM月dd日 hh:mm:ss")}
          </div>
        </div>
      </div>
    );
  }
  renderItem3(item, index) {
    var alertType = item["alertType"];

    var img = "./images/kaisuo.png",
      title = "";
    if (alertType === 1) {
      title = "有人按门铃";
    } else if (alertType === 2) {
      title = "多次尝试密码";
    } else if (alertType === 3) {
      title = "多次尝试卡片";
    } else if (alertType === 4) {
      title = "多次尝试指纹";
    } else if (alertType === 5) {
      title = "请求远程开锁";
    } else if (alertType === 6) {
      title = "有人撬锁";
    } else if (alertType === 7) {
      title = "劫持钥匙抓拍";
    } else if (alertType === 8) {
      title = "管理员抓拍";
    } else if (alertType === 9) {
      title = "密码开锁抓拍";
    } else if (alertType === 10) {
      title = "临时密码抓拍";
    } else if (alertType === 11) {
      title = "卡片开锁抓拍";
    } else if (alertType === 12) {
      title = "指纹开锁抓拍";
    } else if (alertType === 13) {
      title = "人脸识别抓拍";
    } else if (alertType === 14) {
      title = "人脸开锁抓拍";
    } else if (alertType === 15) {
      title = "徘徊抓拍";
    } else {
      title = "未知";
    }

    return (
      <div
        key={`item3-${index}`}
        style={{
          borderBottom: "1px solid rgba(0,0,0,0.1)",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
        onClick={() => {
          if (item["alertType"] === 5) {
            this._parseTime(item["alertTime"]);
          }

          this.setState({ _selectItem: item });
        }}
      >
        <img
          style={{
            height: 50,
            width: 50,
            backgroundColor: "#008cfe",
            borderRadius: 50,
            padding: 10,
            margin: 10,
          }}
          src={img}
          alt=""
        />
        <div style={{ flex: 1 }}>
          <div style={{ fontSize: 14, paddingTop: 5, paddingBottom: 2 }}>
            {title}
          </div>
          <div
            style={{
              fontSize: 12,
              color: "rgba(0,0,0, 0.3)",
              paddingTop: 2,
              paddingBottom: 5,
            }}
          >
            {item["alertTime"] &&
              new Date(item["alertTime"]).format("yyyy年MM月dd日 hh:mm:ss")}
          </div>
        </div>
        <img
          style={{
            height: 100,
            width: 80,
            margin: 10,
          }}
          src={`http://lock.expeedos.cn:8083/pic/${item["pictureUrl"]}`}
          alt=""
        />
      </div>
    );
  }
  renderModel() {
    const { _selectItem, _lockTime, _lock } = this.state;
    var alertType = _selectItem["alertType"];
    var title = "";
    if (alertType === 1) {
      title = "有人按门铃";
    } else if (alertType === 2) {
      title = "多次尝试密码";
    } else if (alertType === 3) {
      title = "多次尝试卡片";
    } else if (alertType === 4) {
      title = "多次尝试指纹";
    } else if (alertType === 5) {
      title = "请求远程开锁";
    } else if (alertType === 6) {
      title = "有人撬锁";
    } else if (alertType === 7) {
      title = "劫持钥匙抓拍";
    } else if (alertType === 8) {
      title = "管理员抓拍";
    } else if (alertType === 9) {
      title = "密码开锁抓拍";
    } else if (alertType === 10) {
      title = "临时密码抓拍";
    } else if (alertType === 11) {
      title = "卡片开锁抓拍";
    } else if (alertType === 12) {
      title = "指纹开锁抓拍";
    } else if (alertType === 13) {
      title = "人脸识别抓拍";
    } else {
      title = "未知";
    }
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.4)",
          display: _selectItem ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          transition: "all 1s ease-out",
        }}
      >
        <div
          style={{
            width: "96%",
            backgroundColor: "white",
            borderRadius: 5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ color: "black" }}>{title}</p>
          <img
            style={{ width: "98%" }}
            src={`http://lock.expeedos.cn:8083/pic/${_selectItem["pictureUrl"]}`}
            alt=""
          />
          <div
            style={{
              width: "100%",
              height: 60,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: 14, color: "gray" }}>{`设备名字: ${_lock["aliasName"] || "--"
              }`}</div>
            <div style={{ fontSize: 14, color: "gray" }}>{`设备ID: ${_lock["deviceMac"] || "--"
              }`}</div>
          </div>
          {alertType === 5 && _lockTime > 0 ? (
            <div
              style={{
                width: "100%",
                height: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "45%",
                  height: 40,
                  border: "1px solid rgba(0,0,0,0.3)",
                  backgroundColor: "rgba(0,0,0,0.1)",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                  borderRadius: 5,
                  color: "red",
                }}
                onClick={() => this.setState({ _selectItem: false })}
              >
                暂不开锁
              </div>
              <div
                style={{
                  width: "45%",
                  height: 40,
                  border: "1px solid #008cfe",
                  backgroundColor: "#008cfe",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                  borderRadius: 5,
                  color: "white",
                }}
                onClick={() =>
                  this.setState({ _remoteAuth: true, _remoteCode: "" })
                }
              >
                {`马上开锁 (${_lockTime})`}
              </div>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                height: 50,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "93%",
                  height: 40,
                  border: "1px solid rgba(0,0,0,0.3)",
                  backgroundColor: "rgba(0,0,0,0.1)",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                  borderRadius: 5,
                  color: "red",
                }}
                onClick={() => this.setState({ _selectItem: false })}
              >
                关闭
              </div>
            </div>
          )}
          <div style={{ height: 10 }}></div>
        </div>
      </div>
    );
  }
  renderModelPassword() {
    const { _remoteAuth } = this.state;

    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.4)",
          display: _remoteAuth ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          transition: "all 1s ease-out",
        }}
      >
        <div
          style={{
            width: "96%",
            marginTop: 50,
            backgroundColor: "white",
            borderRadius: 5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ color: "black" }}>安全验证</p>
          <div
            style={{
              width: "98%",
              height: 120,
              paddingLeft: 20,
              paddingRight: 20,
              marginBottom: 5,
              borderTop: "1px solid rgba(0,0,0,0.2)",
              borderBottom: "1px solid rgba(0,0,0,0.2)",
            }}
          >
            <div
              style={{
                marginTop: 10,
                height: 40,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                fontSize: 14,
              }}
            >
              输入安全密码授权操作
            </div>
            <input
              style={{
                width: "100%",
                padding: "5px",
                height: 40,
                border: "1px solid rgba(0,0,0,0.1)",
                backgroundColor: "transparent",
                borderRadius: 5,
              }}
              type="password"
              placeholder="请输入安全密码"
              onChange={(e) =>
                this.setState({ _remoteCode: e.currentTarget.value })
              }
            />
          </div>

          <div
            style={{
              width: "100%",
              height: 50,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "45%",
                height: 40,
                border: "1px solid rgba(0,0,0,0.3)",
                backgroundColor: "rgba(0,0,0,0.1)",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                borderRadius: 5,
                color: "red",
              }}
              onClick={() =>
                this.setState({ _remoteAuth: false, _remoteCode: "" })
              }
            >
              取消
            </div>
            <div
              style={{
                width: "45%",
                height: 40,
                border: "1px solid #008cfe",
                backgroundColor: "#008cfe",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                borderRadius: 5,
                color: "white",
              }}
              onClick={() => this.remoteOpen()}
            >
              {`马上开锁`}
            </div>
          </div>
          <div style={{ height: 10 }}></div>
        </div>
      </div>
    );
  }

  renderNotice() {
    const { _showNotice, _lock } = this.state;
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          backgroundColor: "transparent",
          display: _showNotice ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          transition: "all 1s ease-out",
        }}
      >
        {_showNotice.length >= 0 &&
          _showNotice.map((item, index) => {
            var type = item["type"];

            if (type === 1) {
              var openType = item["record"]["openType"];
              var img = "./images/kaisuo.png",
                title = "";
              if (openType === 0) {
                title = "指纹";
              } else if (openType === 1) {
                title = "密码";
              } else if (openType === 2) {
                title = "IC卡";
              } else if (openType === 3) {
                title = "APP临时密码";
              } else if (openType === 4) {
                title = "APP远程开锁";
              } else if (openType === 5) {
                title = "默认密码";
              } else if (openType === 7) {
                title = "人脸识别";
              } else {
                title = "未知";
              }

              if (item["key"] && item["key"]["keyIndex"] >= 0) {
                title = title + "用户: " + item["key"]["keyIndex"];
              }
              return (
                <div
                  key={`notce1-${index}`}
                  style={{
                    marginTop: 10,
                    width: "96%",
                    height: 100,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                    backgroundColor: "white",
                  }}
                >
                  <img
                    style={{
                      width: 40,
                      heihgt: 40,
                      margin: "10px",
                      padding: 10,
                      backgroundColor: "#008cfe",
                      borderRadius: 50,
                    }}
                    src={img}
                    alt=""
                  />
                  <div style={{ flex: 1 }}>
                    <p
                      style={{
                        width: "calc(100vw - 140px)",
                        color: "rgba(0,0,0,0.9)",
                        padding: 0,
                        margin: 0,
                        height: 25,
                        fontSize: 16,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {`门锁已打开`}
                    </p>
                    <p
                      style={{
                        color: "rgba(0,0,0,0.4)",
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                      }}
                    >
                      {title}
                    </p>
                  </div>
                  <div
                    style={{
                      width: 70,
                      height: 100,
                      borderLeft: "1px solid rgba(0,0,0,0.2)",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#008cfe",
                    }}
                    onClick={() => {
                      this._showNoticeTime &&
                        clearTimeout(this._showNoticeTime);
                      this.histOpen(_lock["expeedDeviceId"]);
                      this.setState({ _tabValue: 0, _showNotice: false });
                    }}
                  >
                    查看
                  </div>
                </div>
              );
            } else if (type === 2) {
              var alertType = item["record"]["alertType"];

              var img = "./images/kaisuo.png",
                title = "";
              if (alertType === 0) {
                title = "撬锁报警";
              } else if (alertType === 1) {
                title = "多次尝试密码报警";
              } else if (alertType === 2) {
                title = "多次尝试指纹报警";
              } else if (alertType === 3) {
                title = "多次尝试磁卡报警";
              } else if (alertType === 4) {
                title = "低电量报警";
              } else if (alertType === 5) {
                title = "关锁异常报警";
              } else if (alertType === 6) {
                title = "进入管理员菜单";
              } else if (alertType === 7) {
                title = "触发劫持报警";
              } else if (alertType === 8) {
                title = "门铃提醒";
              } else if (alertType === 9) {
                title = "人脸识别报警";
              } else if (alertType === 10) {
                title = "徘徊报警";
              } else {
                title = "未知";
              }
              return (
                <div
                  key={`notce2-${index}`}
                  style={{
                    marginTop: 10,
                    width: "96%",
                    height: 100,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                    backgroundColor: "white",
                  }}
                >
                  <img
                    style={{
                      width: 40,
                      heihgt: 40,
                      margin: "10px",
                      padding: 10,
                      backgroundColor: "#008cfe",
                      borderRadius: 50,
                    }}
                    src={img}
                    alt=""
                  />
                  <div style={{ flex: 1 }}>
                    <p
                      style={{
                        width: "calc(100vw - 140px)",
                        color: "rgba(0,0,0,0.9)",
                        padding: 0,
                        margin: 0,
                        height: 25,
                        fontSize: 16,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {`智能门锁`}
                    </p>
                    <p
                      style={{
                        color: "rgba(0,0,0,0.4)",
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                      }}
                    >
                      {title}
                    </p>
                  </div>
                  <div
                    style={{
                      width: 70,
                      height: 100,
                      borderLeft: "1px solid rgba(0,0,0,0.2)",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#008cfe",
                    }}
                    onClick={() => {
                      this._showNoticeTime &&
                        clearTimeout(this._showNoticeTime);
                      this.histAlert(_lock["expeedDeviceId"]);
                      this.setState({ _tabValue: 1, _showNotice: false });
                    }}
                  >
                    查看
                  </div>
                </div>
              );
            } else if (type === 3) {
              var alertType = item["record"]["alertType"];

              var img = "./images/kaisuo.png",
                title = "";
              if (alertType === 1) {
                title = "有人按门铃";
              } else if (alertType === 2) {
                title = "多次尝试密码";
              } else if (alertType === 3) {
                title = "多次尝试卡片";
              } else if (alertType === 4) {
                title = "多次尝试指纹";
              } else if (alertType === 5) {
                title = "请求远程开锁";
              } else if (alertType === 6) {
                title = "有人撬锁";
              } else if (alertType === 7) {
                title = "劫持钥匙抓拍";
              } else if (alertType === 8) {
                title = "管理员抓拍";
              } else if (alertType === 9) {
                title = "密码开锁抓拍";
              } else if (alertType === 10) {
                title = "临时密码抓拍";
              } else if (alertType === 11) {
                title = "卡片开锁抓拍";
              } else if (alertType === 12) {
                title = "指纹开锁抓拍";
              } else if (alertType === 13) {
                title = "人脸识别抓拍";
              } else if (alertType === 14) {
                title = "人脸开锁抓拍";
              } else if (alertType === 15) {
                title = "徘徊抓拍";
              } else {
                title = "未知";
              }
              return (
                <div
                  key={`notce3-${index}`}
                  style={{
                    marginTop: 10,
                    width: "96%",
                    height: 100,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                    backgroundColor: "white",
                  }}
                >
                  <img
                    style={{
                      width: 40,
                      heihgt: 40,
                      margin: "10px",
                      padding: 10,
                      backgroundColor: "#008cfe",
                      borderRadius: 50,
                    }}
                    src={img}
                    alt=""
                  />
                  <div style={{ flex: 1 }}>
                    <p
                      style={{
                        width: "calc(100vw - 140px)",
                        color: "rgba(0,0,0,0.9)",
                        padding: 0,
                        margin: 0,
                        height: 25,
                        fontSize: 16,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {`智能门锁`}
                    </p>
                    <p
                      style={{
                        color: "rgba(0,0,0,0.4)",
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                      }}
                    >
                      {title}
                    </p>
                  </div>
                  <div
                    style={{
                      width: 70,
                      height: 100,
                      borderLeft: "1px solid rgba(0,0,0,0.2)",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#008cfe",
                    }}
                    onClick={() => {
                      this._showNoticeTime &&
                        clearTimeout(this._showNoticeTime);
                      this.firmwarePictureSearch(_lock["expeedDeviceId"]);
                      this.setState({ _tabValue: 2, _showNotice: false });
                    }}
                  >
                    查看
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
      </div>
    );
  }
}
