import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { API } from "../proxy/BaseHttp";
import { NavBar } from "zarm";

import { RoutePath } from "../proxy/RootRouter";

class ChangePassword extends Component {
  state = {
    isOk: false,
    isPath: false,
    oldPassword: "",
    newPassword: "",
    newPassword2: "",
  };
  async updatePasswd() {
    const { oldPassword, newPassword } = this.state;
    try {
      var res = await API.updatePasswd(oldPassword, newPassword);
      if (res && res.result_code === 0) {
        window.localStorage.setItem("u", newPassword);
        window.alert("修改成功");
        this.goBack();
      } else {
        if (res) window.alert(res["result_msg"]);
        else window.alert("修改失败");
      }
    } catch (e) {}
  }
  gotoChange() {
    const { oldPassword, newPassword, newPassword2 } = this.state;
    if (oldPassword === "" || !oldPassword) {
      window.alert("请输入密码");
    } else if (newPassword.length < 6) {
      window.alert("新密码不得少于6位");
    } else if (newPassword !== newPassword2) {
      window.alert("两次新密码不相同");
    } else {
      this.updatePasswd();
    }
  }
  goBack() {
    this.setState({ isOk: true, isPath: RoutePath.profile });
  }

  render() {
    const { isOk, isPath } = this.state;
    if (isOk) return <Redirect to={{ pathname: isPath, state: {} }} />;
    return <div>{this.renderPassword()}</div>;
  }
  renderPassword() {
    return (
      <div>
        <NavBar
          style={{ backgroundColor: "#008cfe", color: "white" }}
          title="修改密码"
          left={
            <img
              className="left-img"
              src="./images/left.png"
              alt=""
              onClick={() =>
                this.setState({ isOk: true, isPath: RoutePath.profile })
              }
            />
          }
        ></NavBar>

        <div
          style={{
            width: "82%",
            marginLeft: "9%",
            marginTop: 60,
            color: "rgba(0,0,0,0.6)",
            fontWeight: "bold",
            padding: "10px 10px",
            display: "flex",
            flexDirection: "row",
            fontSize: 16,
          }}
        ></div>
        <div
          style={{
            marginTop: 10,
            width: "100%",
            position: "relative",
            marginLeft: "10%",
          }}
        >
          <img
            src="./images/password.png"
            alt=""
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: 20,
              height: 20,
              margin: 10,
            }}
          />
          <input
            style={{
              width: "80%",
              padding: "5px 10px 5px 40px",
              height: 40,
              border: "1px solid rgba(0,0,0,0.1)",
              backgroundColor: "transparent",
              borderRadius: 5,
            }}
            type="password"
            placeholder="请输入旧密码"
            onChange={(e) =>
              this.setState({ oldPassword: e.currentTarget.value })
            }
          />
        </div>
        <div
          style={{
            marginTop: 10,
            width: "100%",
            position: "relative",
            marginLeft: "10%",
          }}
        >
          <img
            src="./images/password.png"
            alt=""
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: 20,
              height: 20,
              margin: 10,
            }}
          />
          <input
            style={{
              width: "80%",
              padding: "5px 10px 5px 40px",
              height: 40,
              border: "1px solid rgba(0,0,0,0.1)",
              backgroundColor: "transparent",
              borderRadius: 5,
            }}
            type="password"
            placeholder="请输入新密码"
            onChange={(e) =>
              this.setState({ newPassword: e.currentTarget.value })
            }
          />
        </div>
        <div
          style={{
            marginTop: 10,
            width: "100%",
            position: "relative",
            marginLeft: "10%",
          }}
        >
          <img
            src="./images/password.png"
            alt=""
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: 20,
              height: 20,
              margin: 10,
            }}
          />
          <input
            style={{
              width: "80%",
              padding: "5px 10px 5px 40px",
              height: 40,
              border: "1px solid rgba(0,0,0,0.1)",
              backgroundColor: "transparent",
              borderRadius: 5,
            }}
            type="password"
            placeholder="请重复输入新密码"
            onChange={(e) =>
              this.setState({ newPassword2: e.currentTarget.value })
            }
          />
        </div>
        <button
          style={{
            width: "80%",
            marginLeft: "10%",
            height: 40,
            textAlign: "center",
            backgroundColor: "#008cfe",
            borderRadius: 5,
            color: "white",
            fontSize: 14,
            marginTop: 30,
            border: 0,
          }}
          onClick={() => this.gotoChange()}
        >
          确定
        </button>
      </div>
    );
  }
}
export default ChangePassword;
