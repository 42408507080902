import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { API } from "../proxy/BaseHttp";

import { RoutePath } from "../proxy/RootRouter";
import { NavBar } from "zarm";

export default class Link1 extends Component {
  state = {
    isOk: false,
    isPath: false,
    deviceList: [],
  };

  componentDidMount() {
    this.user = window.localStorage.getItem("user");
    if (this.user) this.user = JSON.parse(this.user);

    this.getSSID();
    this.timer = setInterval(() => {
      console.log("this.getSSID()");
      this.getSSID();
    }, 3000);
    // setTimeout(() => {
    //   this.getSSID();
    // }, 1000);
  }
  componentWillUnmount() {
    console.log("componentWillUnmount");
    this.timer && clearInterval(this.timer);
  }
  async getSSID() {
    try {
      var res = await API.getSSIDList();
      console.log("getSSIDList ", res);
      if (res) {
        this.timer && clearInterval(this.timer);
        this.setState({ isOk: true, isPath: RoutePath.link2 });
      }
    } catch (e) {}
  }

  render() {
    const { isOk, isPath, deviceList } = this.state;
    if (isOk) return <Redirect to={{ pathname: isPath, state: {} }} />;
    return (
      <div>
        <NavBar
          style={{ backgroundColor: "#008cfe", color: "white" }}
          title="连接设备热点"
          left={
            <img
              className="left-img"
              src="./images/left.png"
              alt=""
              onClick={() =>
                this.setState({ isOk: true, isPath: RoutePath.home })
              }
            />
          }
        ></NavBar>
        <div
          style={{
            marginTop: 10,
            marginLeft: "5%",
            width: "90%",
            height: "auto",
            border: "1px solid rgba(0,0,0,0.1)",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.1)",
            borderRadius: 10,
          }}
        >
          <div
            style={{
              width: 50,
              color: "#128CFE",
              textAlign: "center",
            }}
          >
            1
          </div>
          <div
            style={{
              flex: 1,
              overflow: "auto",
              height: "auto",
              wordWrap: "break-word",
              backgroundColor: "white",
              padding: "10px 5px",
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
            }}
          >
            复位设备，或者进入设备管理界面，选择热点联网模式，具体方法请参考产品说明书
          </div>
        </div>

        <div
          style={{
            marginTop: 10,
            marginLeft: "5%",
            width: "90%",
            height: "auto",
            border: "1px solid rgba(0,0,0,0.1)",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.1)",
            borderRadius: 10,
          }}
        >
          <div
            style={{
              width: 50,
              color: "#128CFE",
              textAlign: "center",
            }}
          >
            2
          </div>
          <div
            style={{
              flex: 1,
              overflow: "auto",
              height: "auto",
              wordWrap: "break-word",
              backgroundColor: "white",
              padding: "10px 5px",
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
            }}
          >
            复位设备，或者进入设备管理界面，选择热点联网模式，具体方法请参考产品说明书
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: 50, height: 50, margin: 10 }}
                src="./images/setting.jpg"
              />
            </div>
            找到并点击进入Wi-Fi页面
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: 50, height: 50, margin: 10 }}
                src="./images/wifi.jpg"
              />
            </div>
            连接至以xxxx开头的Wi-Fi
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "90%", margin: 15 }}
                src="./images/connect.jpg"
              />
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: 10,
            marginLeft: "5%",
            width: "90%",
            height: "auto",
            border: "1px solid rgba(0,0,0,0.1)",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.1)",
            borderRadius: 10,
          }}
        >
          <div
            style={{
              width: 50,
              color: "#128CFE",
              textAlign: "center",
            }}
          >
            3
          </div>
          <div
            style={{
              flex: 1,
              overflow: "auto",
              height: "auto",
              wordWrap: "break-word",
              backgroundColor: "white",
              padding: "10px 5px",
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
            }}
          >
            连接之后返回该页面，等待页面自动跳转
          </div>
        </div>

        <div
          style={{
            marginTop: 10,
            marginLeft: "5%",
            width: "90%",
            textAlign: "center",
            color: "#B00000",
          }}
        >
          -----------注意事项-----------
          <div
            style={{
              textAlign: "left",
              padding: "10px 0 0 0",
              color: "#D84955",
              fontSize: 14,
            }}
          >
            1.如果连接过程中，手机提示该热点无法上网，是否切换，请选择“不切换”或“继续使用”
          </div>
          <div
            style={{
              textAlign: "left",
              padding: "10px 0 0 0",
              color: "#D84955",
              fontSize: 14,
            }}
          >
            2.如果连接之后，Wi-Fi会自动断开，或长时间停留在该页面，请关闭手机的4G网络后重试
          </div>
        </div>
      </div>
    );
  }
}
