import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { API } from "../proxy/BaseHttp";

import { RoutePath } from "../proxy/RootRouter";
import { NavBar } from "zarm";
import { DB } from "../proxy/BaseModal";

export default class Link4 extends Component {
  state = {
    isOk: false,
    isPath: false,
    deviceList: [],
    password: "",
  };

  componentDidMount() {
    this.user = window.localStorage.getItem("user");
    if (this.user) this.user = JSON.parse(this.user);
  }
  goNext() {
    const { password } = this.state;
    if (password === "") {
      window.alert("请输入WIFI密码");
    } else {
      DB.PASSWORD({ type: "SET", value: password });
      this.setState({ isOk: true, isPath: RoutePath.link2 });
    }
  }

  render() {
    const { isOk, isPath } = this.state;
    if (isOk) return <Redirect to={{ pathname: isPath, state: {} }} />;
    return (
      <div>
        <NavBar
          style={{ backgroundColor: "#008cfe", color: "white" }}
          title="输入Wi-Fi密码"
          left={
            <img
              className="left-img"
              src="./images/left.png"
              alt=""
              onClick={() => {
                this.setState({ isOk: true, isPath: RoutePath.home });
              }}
            />
          }
        ></NavBar>

        <div
          style={{
            marginTop: 30,
            width: "90%",
            position: "relative",
            marginLeft: "5%",
            border: "1px solid #DDDDDD",
            borderRadius: 5,
          }}
        >
          <img
            src="./images/password.png"
            alt=""
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: 40,
              height: 40,
              padding: 10,
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
              backgroundColor: "#F5F5F5",
            }}
          />
          <input
            style={{
              width: "100%",
              padding: "5px 10px 5px 50px",
              height: 40,
              border: 0,
              backgroundColor: "transparent",
            }}
            placeholder="请输入WIFI密码"
            type="text"
            onChange={(e) => this.setState({ password: e.currentTarget.value })}
          />
        </div>
        <button
          style={{
            width: "90%",
            marginLeft: "5%",
            height: 40,
            textAlign: "center",
            backgroundColor: "#008cfe",
            borderRadius: 5,
            color: "white",
            fontSize: 14,
            marginTop: 50,
            border: 0,
          }}
          onClick={() => this.goNext()}
        >
          下一步
        </button>
      </div>
    );
  }
}
